<template>
  <b-sidebar
    id="ColorSidebar"
    ref="ColorSidebar"
    v-model="visible"
    right
    backdrop
    backdrop-variant="transparent"
    shadow
  >
    <div class="mt-4 px-3 py-2">
      <b-card v-if="onDashboardRoute || onTxQueueRoute">
        <div v-if="onTxQueueRoute">
          <b-form-checkbox v-model="notifyAll" switch @change="handleNotifyAll">
            <span class="font-weight-normal text-muted">
              แจ้งเตือนทั้งหมด
            </span></b-form-checkbox
          >
          <p class="description text-muted">
            เปิดเสียง เมือมีคนไข้ส่งตรวจ ของแพทย์ทุกท่าน
          </p>
        </div>
        <b-form-group label="ระยะห่างตารางนัด :" label-class="text-muted">
          <b-form-input
            v-model="slideValue"
            type="range"
            min="0"
            max="4"
            @input="durationInput"
          ></b-form-input>
          <b-input-group class="" append="นาที">
            <b-form-input
              class="text-right"
              v-model="fcSlotDuration"
              disabled
            ></b-form-input></b-input-group
        ></b-form-group>
      </b-card>
      <b-card>
        <b-table-simple hover small caption-top>
          <caption class="pt-0">
            สถานะ:
          </caption>
          <b-tr v-for="status in statusList" :key="status.code">
            <b-td
              class="text-center border-white rounded"
              :style="{ backgroundColor: status.desc, color: 'white' }"
              >{{ status.label }}</b-td
            >
          </b-tr>
        </b-table-simple>
      </b-card>
      <b-card v-if="onQueueRoute">
        <b-table-simple hover small caption-top>
          <caption class="pt-0">
            แพทย์:
          </caption>
          <b-tr v-for="doctor in getDoctorList" :key="doctor.code">
            <b-td
              class="text-center border-white rounded"
              :style="{ backgroundColor: doctor.desc }"
              >{{ doctor.label }}</b-td
            >
          </b-tr>
        </b-table-simple>
      </b-card>

      <!-- <b-card class="m-3" no-body>
        <div class="fc-content">
          <div
            class="fc-time rounded-top py-1"
            style="background-color: rgb(92, 141, 255); color: white"
          >
            <span>10:00 - 11:00</span>
          </div>
          <div class="fc-title" style="background-color: #ffe9d6">
            พาสเทล โปรเจ็ค <br />DN: 2563-01-1111<br />- ขูดหินปูน<br />-
            อุดฟัน<br />
          </div>
        </div>
      </b-card> -->
    </div>
  </b-sidebar>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import { eventBus } from "@/main";

export default {
  name: "ColorSidebar",
  data() {
    return {
      visible: false,
      branchMembers: [],
      fcSlotDuration: "00:15",

      minStep: ["00:05", "00:10", "00:15", "00:20", "00:30"],
      slideValue: 2,

      notifyAll: true,
    };
  },
  created() {
    this.init();
  },
  computed: {
    ...mapGetters({
      getAppointmentStatusList: "moduleMaster/getAppointmentStatusList",
      getDoctorList: "moduleUser/getDoctorList",
    }),
    statusList() {
      return this.getAppointmentStatusList.filter((item) => item.code != 8);
    },
    onQueueRoute() {
      return (
        this.$route.name === "QueueManage" || this.$route.name === "QueueList"
      );
    },
    onDashboardRoute() {
      return this.$route.name === "Dashboard";
    },
    onTxQueueRoute() {
      return this.$route.name === "DoctorsTreatingQueue";
    },
  },
  methods: {
    ...mapActions({
    }),
    init() {
    },
    toggle({ slotDuration, notifyAll }) {
      this.visible = !this.visible;
      if (slotDuration) {
        this.fcSlotDuration = slotDuration;
        this.slideValue = this.minStep.findIndex((e) => e === slotDuration);
      }
      this.notifyAll = notifyAll;
    },
    durationInput(e) {
      this.fcSlotDuration = this.minStep[e];
      this.emitSetting();
      localStorage.setItem("fcSlotDuration", this.fcSlotDuration);
    },
    handleNotifyAll() {
      this.emitSetting();
      localStorage.setItem("notifyAll", this.notifyAll);
    },
    emitSetting() {
      eventBus.$emit("slotDurationSetting", this.fcSlotDuration);
      eventBus.$emit("notifyAllSetting", this.notifyAll);
    },
  },
};
</script>

<style>
</style>