<template>
  <b-modal
    modal-class="component-modal-treat-diag-list small-text"
    id="treatDiagListModal"
    ref="treatDiagListModal"
    size="lg"
    title="รายการวินิจฉัย"
    ok-only
    ok-title="เลือก"
    scrollable
    no-close-on-backdrop
    @ok="saveSelectedItemes"
    @hidden="setDefault($event)"
  >
    <b-form-group>
      <b-form-input
        autofocus
        v-model="filter"
        size=""
        type="search"
        id="filterInput"
        ref="filterInput"
        placeholder="ค้นหาชื่อวินิจฉัย เช่น Dental caries, Acute periodontis"
        @input="searchFilter"
      ></b-form-input>
    </b-form-group>
    <b-table
      id="treatDiagListTable"
      ref="treatDiagListTable"
      hover
      selectable
      small
      sort-icon-left
      select-mode="single"
      :fields="fields"
      :items="diagList"
      :filter="filter"
      @row-clicked="onRowSelected"
    >
      <template v-slot:cell(index)="row">{{ row.index + 1 }}.</template>
      <template v-slot:cell(favourite)="row">
        <b-button
          size="sm"
          variant="outline-info"
          :pressed="row.item.favorite"
          @click="favToggle(row.item)"
        >
          <i class="far fa-star"></i>
        </b-button>
      </template>
    </b-table>
    <Loading v-if="isLoading"></Loading>
  </b-modal>
</template>

<script>
import { mapGetters } from "vuex";
import Loading from "@/components/Loading";
// import "@/theme/modal/TreatDiagList.scss";

export default {
  name: "TreatDiagListModal",
  components: {
    Loading,
  },
  data() {
    return {
      isLoading: false,
      fields: [
        {
          key: "index",
          label: "",
        },
        {
          key: "code",
          label: "รหัส",
          sortable: true,
        },
        {
          key: "nameEn",
          label: "Diagnosis",
          sortable: true,
        },
        {
          key: "category",
          label: "ประเภทวินิจฉัย",
          sortable: true,
        },
        {
          key: "favourite",
          label: "ใช้ประจำ",
          class: "text-center",
        },
      ],
      diagItem: null,
      filter: null,
      fav: false,
    };
  },
  computed: {
    ...mapGetters({
      getTreatDiagList: "moduleAppointment/getTreatDiagList",
      getTreatDiagCateList: "moduleAppointment/getTreatDiagCateList",
      getTreatDiagCateBy: "moduleAppointment/getTreatDiagCateBy",
    }),
    diagList() {
      return this.getTreatDiagList.map(item => {
        return {
          id: item.id,
          code: item.code,
          nameEn: item.nameEn,
          nameTh: item.nameTh,
          category: this.getTreatDiagCateBy(item.cateId, "id").nameTh,
          creationDt: item.creationDt,
          favorite: item.favorite,
        };
      });
    },
  },
  methods: {
    show(data) {
      this.diagItem = data;
      if (data != null) {
        this.setSelectRow(this.diagItem);
      }
      this.$refs["treatDiagListModal"].show();
      // this.$bvModal.show("treatDiagListModal");
    },
    onRowSelected(item) {
      this.diagItem = item;
    },

    setSelectRow(data) {
      this.$nextTick(() => {
        this.$nextTick(() => {
          var index = this.$refs.treatDiagListTable.filteredItems.findIndex(
            filteredItem => filteredItem.id == data.id
          );
          if (index >= 0) {
            this.$refs.treatDiagListTable.selectRow(index);
          }
        });
      });
    },
    saveSelectedItemes() {
      this.$emit("selectedDiagItem", this.diagItem);
    },
    favToggle(data) {
      this.isLoading = true;
      if (data.favorite == false) {
        this.$store
          .dispatch("moduleAppointment/updateTreatDiagFevorite", {
            event: data,
            clinicUrl: this.$route.params.clinicUrl,
            branchUrl: this.$route.params.branchUrl,
          })
          .finally(() => {
            this.isLoading = false;
          });
      } else if (data.favorite == true) {
        this.$store
          .dispatch("moduleAppointment/deleteTreatDiagFevorite", {
            event: data,
            clinicUrl: this.$route.params.clinicUrl,
            branchUrl: this.$route.params.branchUrl,
          })
          .finally(() => {
            this.isLoading = false;
          });
      }
    },
    setDefault(event) {
      this.$emit("hidden");
      this.filter = "";
    },
    searchFilter() {
      this.setSelectRow(this.diagItem);
    },
  },
  mounted() {},
};
</script>

<style>
</style>