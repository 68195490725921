<template>
  <b-modal
    modal-class="component-modal-treatment-planning"
    id="medical-cert-modal"
    ref="medicalCertModal"
    size="xl fluid"
    no-close-on-backdrop
    ok-title="พิมพ์"
    cancel-title="เคลียร์ข้อมูล"
    cancel-variant="danger"
  >
    <template #modal-title>
      <h5>
        ใบรับรองทันตแพทย์
        <span class="text-danger">(สำหรับพิมพ์ หรือบันทึกเป็นPDFเท่านั้น)</span>
      </h5>
    </template>
    <div>
      <b-row class="mb-1">
        <b-col cols="2" class>ชื่อ:</b-col>
        <b-col cols>{{ ptFullname }}</b-col>
      </b-row>
      <b-row v-if="ptAddress" class="mb-3">
        <b-col cols="2" class>ที่อยู่:</b-col>
        <b-col cols>{{ ptAddress }}</b-col>
      </b-row>
      <b-row class="mb-1">
        <b-col cols="2" class>ทพ./ทพญ.:</b-col>
        <b-col>
          <b-form-select
            class="col-6"
            :class="{ 'is-invalid': $v.title.$error }"
            v-model="$v.title.$model"
            :options="titleOptions"
          >
            <template v-slot:first>
              <b-form-select-option :value="null" disabled
                >กรุณาเลือกคำนำหน้า</b-form-select-option
              >
            </template>
          </b-form-select>
        </b-col>
      </b-row>

      <b-row id="tx-plan-doctor-name" class="mb-1">
        <b-col cols="2" class>แพทย์:</b-col>
        <b-col>
          <b-form-select
            class="col-6"
            :class="{ 'is-invalid': $v.selectId.$error }"
            v-model="$v.selectId.$model"
            :options="doctorList"
            text-field="fullName"
            value-field="uid"
          >
            <template v-slot:first>
              <b-form-select-option :value="null" disabled
                >รายชื่อแพทย์</b-form-select-option
              >
            </template>
          </b-form-select>
        </b-col>
      </b-row>
      <b-row class="">
        <b-col sm="2"> ความเห็นทันตแพทย์: </b-col>
        <b-col sm="10">
          <b-form-textarea
            class="col-6"
            placeholder="..."
            rows="6"
            v-model="commentDetail"
          ></b-form-textarea>
        </b-col>
      </b-row>
    </div>
    <div>
      <b-table
        class="small-text"
        small
        sticky-header
        sort-icon-left
        sort-by="creationDt"
        sort-desc
        caption-top
        hover
        :items="treatmentItems"
        :fields="tableHeaders"
        select-mode="multi"
        selectable
        @row-selected="onRowSelected"
      >
        <template #table-caption
          >เลือกรายการรักษาที่ต้องการออกใบรับรอง</template
        >

        <template #cell(selected)="{ rowSelected }">
          <template v-if="rowSelected">
            <span aria-hidden="true">&check;</span>
            <span class="sr-only">Selected</span>
          </template>
          <template v-else>
            <span aria-hidden="true">&nbsp;</span>
            <span class="sr-only">Not selected</span>
          </template>
        </template>
      </b-table>
    </div>

    <div class="d-none">
      <div ref="header">
        <b-row>
          <b-col cols="2" class="text-center">
            <!-- <Logo class="" :src="logo" :callback="loadLogoCallback" /> -->
            <b-img fluid :src="logo" :alt="clinic.name" style="height: 95px" />
          </b-col>
          <b-col>
            <h4 class="mb-1">{{ clinic.name }}</h4>
            <p v-if="branchInfo && branchInfo.address" class="mb-1 my-justify">
              {{ branchInfo.address }}
            </p>
            <p class="mb-1">
              <span v-if="branchInfo && branchInfo.tel">
                โทร: {{ branchInfo.tel }}
              </span>
              <span v-if="branchInfo && branchInfo.line" class="ml-3">
                Line ID: {{ branchInfo.line }}
              </span>
            </p>
            <p v-if="branchInfo && branchInfo.website" class="mb-1">
              {{ branchInfo.website }}
            </p>
          </b-col>
        </b-row>

        <hr />
      </div>
      <div ref="body">
        <h5 class="text-center my-3">ใบรับรองทันตแพทย์</h5>
        <p class="text-right mr-3">วันที่ {{ today }}</p>
        <p style="text-indent: 50px; line-height: 2" class="my-justify">
          ข้าพเจ้า {{ title }}
          {{ doctorObj.fullName }}
          เป็นผู้จดทะเบียนประกอบโรคศิลปะแผนปัจจุบันชั้นหนึ่ง สาขาทันตกรรม
          ใบอนุญาตที่ ท {{ doctorObj.drLicenseNo }} ได้ทำการตรวจ/รักษา
          {{ ptFullname }} อายุ {{ calAge(patient.dob) || "________" }} ปี DN.
          {{ patient.dn }}
        </p>
        <!-- <p style="text-indent: 50px; line-height: 2" class="my-justify">
          ข้าพเจ้า {{title}}.
          {{ doctorObj.label }}
          เป็นผู้จดทะเบียนประกอบโรคศิลปะแผนปัจจุบันชั้นหนึ่ง สาขาทันตกรรม
          ใบอนุญาตที่ ท {{ doctorObj.drLicenseNo }} ได้ทำการตรวจ/รักษา
          {{ ptFullname }} อายุ {{ calAge(patient.dob) || "________" }} ปี DN.
          {{ patient.dn }} ที่อยู่ปัจจุบัน
          {{
            ptAddress ||
            "__________________________________________________________"
          }}
        </p> -->
        <p class="mb-1">ได้รับการวินิจฉัย และตรวจรักษา:</p>
        <div v-if="selectedItem.length === 0">
          <p class="line-container">
            <span class="printable-line"></span>
          </p>
          <p class="line-container">
            <span class="printable-line"></span>
          </p>
          <p class="line-container">
            <span class="printable-line"></span>
          </p>
        </div>
        <div v-else>
          <div v-for="(items, key) in groupSelectedItem" :key="key">
            <p class="mb-1">วันที่ {{ key }}</p>
            <ul :style="{ listStyleType: 'none' }">
              <li v-for="(item, index) in items" :key="item.id">
                {{ index + 1 }}. ตำแหน่ง/ซี่ฟัน
                {{
                  item.toothItems && item.toothItems.length > 0
                    ? item.toothNumberSurfaces
                    : "____________"
                }}
                ได้รับการวินิจฉัย
                {{ item.dxName || "______________________________" }}
                รักษาด้วยการ
                {{ item.name }}
              </li>
            </ul>
          </div>
        </div>

        <p class="mb-1">ความเห็นของทันตแพทย์ :</p>
        <div v-if="!commentDetail">
          <p class="line-container">
            <span class="printable-line"></span>
          </p>
          <p class="line-container">
            <span class="printable-line"></span>
          </p>
          <p class="line-container">
            <span class="printable-line"></span>
          </p>
        </div>
        <div v-else>
          <p class="my-justify" style="text-indent: 50px; line-height: 2">
            {{ commentDetail }}
          </p>
        </div>
      </div>
      <div ref="footer">
        <b-row class="mr-3" style="margin-top: 8rem">
          <b-col class="text-right">
            <span class="border-top px-4 py-3"
              >{{ title }}{{ doctorObj.fullName }}</span
            >
          </b-col>
        </b-row>
      </div>
    </div>
    <template #modal-footer>
      <div class="w-100">
        <b-button variant="primary" class="float-right" @click="generatePdf">
          พิมพ์
        </b-button>
        <b-button
          variant="info"
          class="float-right mr-2"
          :disabled="isFileStorageExpired"
          @click="uploadPdf"
        >
          Upload
        </b-button>
      </div>
    </template>
    <PdfExport ref="pdfExport" />
    <Dialog ref="Dialog"></Dialog>
  </b-modal>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import Dialog from "@/components/modal/Dialog";

import PdfExport from "@/components/PdfExport";
import moment from "moment";

import { required } from "vuelidate/lib/validators";

export default {
  name: "MedicalCertModal",
  components: {
    PdfExport,
    Dialog,
  },
  data() {
    return {
      patient: {},
      clinic: {},
      logo: null,
      titleOptions: ["ทพ.", "ทพญ."],
      title: null,
      selectId: null,
      // txDate: new Date(),
      commentDetail: "",
      tableHeaders: [
        {
          key: "selected",
          label: "",
          class: "text-center",
          thStyle: "min-width: 32px",
        },
        {
          label: "วันที่ตรวจ/รักษา",
          sortable: true,
          key: "creationDt",
          formatter: this.formatDate,
        },
        {
          label: "ซี่ฟัน",
          key: "toothNumberSurfaces",
          formatter: e => {
            return e ? e : "";
          },
        },
        {
          label: "วินิจฉัย",
          key: "dxName",
        },
        {
          label: "รายการ",
          key: "name",
        },
        {
          label: "แพทย์",
          key: "doctorName",
        },
      ],
      selectedItem: [],

      isPdfCreating: false,
    };
  },
  validations: {
    title: { required },
    selectId: { required },
    // treatment: { required },
  },
  watch: {},
  created() {
    this.init();
  },
  computed: {
    ...mapGetters({
      branchInfo: "moduleUser/getBranchInfo",
      getTreatmentHistoryList: "moduleTreatment/getTreatmentHistoryList",
      getTreatDiagBy: "moduleAppointment/getTreatDiagBy",

      getDoctorList: "moduleUser/getDoctorList",
      getUserList: "moduleUser/getUserList",
      getUserListIncludeDeletedBy: "moduleUser/getUserListIncludeDeletedBy",

      userInfo: "moduleUser/getUserInfo",
      isDoctor: "moduleUser/isDoctor",
      viewFile: "moduleContent/viewFile",
      isFileStorageExpired: "moduleUser/isFileStorageExpired",
    }),
    today() {
      return moment().locale("th").add(543, "years").format("D MMMM YYYY");
    },
    ptFullname() {
      return (
        (this.patient?.titleNameTh ? this.patient?.titleNameTh + " " : "") +
        (this.patient?.firstNameTh ? this.patient?.firstNameTh + " " : "") +
        (this.patient?.lastNameTh ? this.patient?.lastNameTh : "")
      );
    },
    ptAddress() {
      let { address, subDistrict, district, province, country, postcode } =
        this.patient;
      let addressArr = [
        address,
        subDistrict,
        district,
        province,
        country,
        postcode,
      ];
      return addressArr.filter(i => i).join(" ");
    },
    doctorList() {
      return this.getDoctorList.map(doctor => {
        return {
          ...doctor,
          drLicenseNo: this.getUserList.find(user => user.uid === doctor.uid)
            ?.drLicenseNo,
        };
      });
    },
    doctorObj() {
      return this.doctorList.find(i => this.selectId === i.uid) || {};
    },
    treatmentItems() {
      return this.getTreatmentHistoryList
        .map(i => i.items)
        .flat()
        .filter(i => [1, 2].includes(i.typeId))
        .map(i => {
          return {
            ...i,
            doctorName: this.getUserListIncludeDeletedBy(i.actionUid, "uid")
              .fullName,
            dxName: this.getTreatDiagBy(i.diagItems, "id").nameEn,
            toothNumberSurfaces: `${i.toothItems || ""} ${this.formatSurfaces(
              i
            )}`,
          };
        });
    },
    groupSelectedItem() {
      const groupBy = (x, f) =>
        x.reduce((a, b, i) => ((a[f(b, i, x)] ||= []).push(b), a), {});
      let item = this.selectedItem
        .slice(0)
        .sort((a, b) => Date.parse(a.creationDt) - Date.parse(b.creationDt))
        .map(i => {
          return { ...i, formattedDate: this.formatDate(i.creationDt) };
        });
      return groupBy(item, i => i.formattedDate);
    },
  },
  methods: {
    ...mapActions({}),
    init() {},
    show(patient) {
      this.$refs["medicalCertModal"].show();
      this.clinic = this.branchInfo.clinic;
      this.patient = patient;
      this.logo = this.viewFile(this.branchInfo.clinic.logo) || null;
    },
    onRowSelected(items) {
      this.selectedItem = items;
    },
    loadLogoCallback(src) {
      this.logo = src;
    },

    async uploadPdf(bvModalEvt) {
      bvModalEvt.preventDefault();
      this.$v.$touch();
      if (this.$v.$invalid) return;

      this.isPdfCreating = true;

      await this.$nextTick();
      await this.$refs.pdfExport.generatePdf({
        header: this.$refs.header,
        body: this.$refs.body,
        footer: this.$refs.footer,
        option: {
          isUpload: true,
          patientId: this.patient.id,
          remarkTitle: "Med_Cert_"
        },
      });
      this.isPdfCreating = false;
      this.$v.$reset();
    },
    async generatePdf(bvModalEvt) {
      bvModalEvt.preventDefault();
      this.$v.$touch();
      if (this.$v.$invalid) return;

      this.isPdfCreating = true;

      await this.$nextTick();
      await this.$refs.pdfExport.generatePdf({
        header: this.$refs.header,
        body: this.$refs.body,
        footer: this.$refs.footer,
      });
      this.isPdfCreating = false;
      this.$v.$reset();
    },
    formatDate(date) {
      return moment(date).add(543, "years").locale("th").format("D MMMM YYYY");
    },
    formatSurfaces(item) {
      let surfaces = [
        { id: 5, label: "O", order: 1 },
        { id: 3, label: "M", order: 2 },
        { id: 4, label: "D", order: 3 },
        { id: 1, label: "B", order: 4 },
        { id: 2, label: "Li", order: 5 },
      ];
      return (
        item.surfaceItems
          ?.map(i => surfaces.find(j => j.id === i))
          .sort((a, b) => a.order - b.order)
          .map(i => i.label)
          .join("") || ""
      );
    },
    calAge(dateString) {
      if (!dateString) return;
      const date = moment(dateString, "YYYY-MM-DD");
      return moment().diff(date, "years");
    },
  },

  mounted() {
    if (this.isDoctor) {
      this.selectId = this.userInfo.uid;
    }
  },
};
</script>

<style>
.signature {
  width: 200px;
  margin-top: 50px;
}

.line-container {
  width: 100%;
  height: 20px;
  display: table;
}
.printable-line {
  display: table-cell;
  border-bottom: 1px solid black;
}
</style>