<template>
  <b-modal
    id="SelectAptModal"
    ref="SelectAptModal"
    :title="title"
    centered
    size="lg"
    hide-footer
  >
    <b-table
      id="SelectAptTable"
      small
      hover
      head-variant="light"
      sort-icon-left
      :items="matchedApt"
      :fields="fields"
      sort-by="extendedProps.treatmentPeriod"
      @row-clicked="openModal"
    >
      <template v-slot:cell(index)="row"> {{ row.index + 1 }}. </template>
      <template v-slot:cell(treatmentTitle)="row">
        <span v-html="row.item.extendedProps.treatmentTitle"></span>
      </template>
      <template v-slot:cell(resourceId)="row">
        <span>{{ getRoomListBy(row.item.resourceId, "id").name }}</span>
      </template>
      <!-- <template v-slot:cell(dropdown)="row"> </template> -->
    </b-table>
    <Dialog ref="Dialog"></Dialog>
    <Loading v-if="isLoading"></Loading>
  </b-modal>
</template>

<script>
import Dialog from "@/components/modal/Dialog";
import Loading from "@/components/Loading";

import { mapActions, mapGetters } from "vuex";
import { eventBus } from "@/main";

export default {
  name: "SelectApt",
  components: {
    Dialog,
    Loading
  },
  data() {
    return {
      isLoading: false,
      patientName: "",
      matchedApt: [],
      from: null,
      fields: [
        {
          key: "index",
          label: ""
          // sortable: true,
        },
        {
          key: "extendedProps.treatmentPeriod",
          label: "เวลา"
          // sortable: true,
        },
        // {
        //   key: "extendedProps.dn",
        //   label: "DN.",
        //   sortable: true,
        // },
        // {
        //   key: "extendedProps.patientName",
        //   label: "ชื่อ",
        //   sortable: true,
        // },
        {
          key: "extendedProps.doctorName",
          label: "แพทย์"
          // sortable: true,
        },
        {
          key: "resourceId",
          label: "ห้อง"
          // sortable: true,
        },
        {
          key: "treatmentTitle",
          label: "นัดเพื่อ"
          // sortable: true,
        }
      ]
    };
  },

  computed: {
    ...mapGetters({
      getBranchInfo: "moduleUser/getBranchInfo",
      getRoomList: "moduleUser/getRoomList",
      getRoomListBy: "moduleUser/getRoomListBy",
      getDoctorList: "moduleUser/getDoctorList",
      branchLinkedWithNhso: "moduleUser/getLinkedWithNhso"
    }),
    title() {
      return this.from === "payment"
        ? `"${this.patientName}" มีนัดในวันนี้แล้ว กรุณาเลือกนัดหมายที่ต้องการบันทึกการรักษา`
        : `"${this.patientName}" มีนัดในวันนี้แล้ว กรุณาเลือกนัดหมายที่ต้องการส่งตรวจ`;
    }
  },
  created() {
    this.init();
  },
  methods: {
    init() {},
    show(matchedApt, patient, from) {
      this.$bvModal.show("SelectAptModal");
      this.matchedApt = matchedApt;
      this.patientName = this.matchedApt[0].extendedProps.patientName;
      this.from = from;
    },
    async openModal(appointment) {
      this.$bvModal.hide("SelectAptModal");
      if (this.from === "payment" && this.branchLinkedWithNhso) {
        await this.$refs.Dialog.showAlertConfirm(
          "โครงการบัตรประชาชนใบเดียวฯ?",
          "บันทึกการรักษาคนไข้โครงการฯ",
          null,
          "โครงการบัตรประชาชนใบเดียวฯ",
          "คนไข้ชำระเอง"
        )
          .then(async res => {
            const { isDismissed, dismiss, isConfirmed } = res;
            if (isDismissed && dismiss === "backdrop") return;
            const linkedWithNhso = isConfirmed;
            this.isLoading = true;
            await this.$store.dispatch(
              "moduleAppointment/confirmQueueCheckIn",
              {
                event: {
                  linkedWithNhso,
                  roomId: appointment.extendedProps.roomId,
                  doctorUid: appointment.extendedProps.doctor,
                  aptTreatItems: appointment.extendedProps.treatmentList
                },
                id: appointment.id,
                clinicUrl: this.$route.params.clinicUrl,
                branchUrl: this.$route.params.branchUrl
              }
            );
            eventBus.$emit("openPaymentHistoryModal", appointment);
          })
          .catch(err => {
            console.error(err);
          })
          .finally(() => {
            this.isLoading = false;
          });
        return false;
      }
      if (this.from === "payment") {
        eventBus.$emit("openPaymentHistoryModal", appointment);
        return false;
      }
      eventBus.$emit("openCheckInModal", appointment);
    }
  }
};
</script>

<style>
#SelectAptTable td {
  cursor: pointer;
}
</style>