<template>
  <b-modal
    modal-class="component-modal-searchcustomer"
    ref="SearchCustomerModal"
    size="xl"
    title="ค้นหาคนไข้"
    hide-footer
    static
    @hide="defaultValue"
    @shown="focusSearchInput"
  >
    <b-input-group class="mb-3">
      <b-input
        ref="searchInput"
        type="search"
        class="form-control required col"
        placeholder="ค้นหาจาก ชื่อ-สกุล, เบอร์โทร หรือ DN"
        v-model="searchTable"
        @input="debounceSearchCustomer"
        @keyup.enter="searchCustomer"
      />
      <b-input-group-append is-text>
        <b-form-checkbox
          switch
          v-model="isEveryBranch"
          v-b-tooltip.hover
          title="ค้นหาคนไข้ทุกสาขา(Beta)"
        >
          <span class="small-text">ทุกสาขา</span>
        </b-form-checkbox>
      </b-input-group-append>
    </b-input-group>
    <b-table
      sticky-header="70vh"
      head-variant="light"
      hover
      small
      sort-icon-left
      sort-by="creationDt"
      :sort-desc="true"
      :busy="loadingSearch"
      :items="patientList"
      :fields="tableHeader"
      @row-clicked="selectCustomer"
      ref="tableSearchPatient"
      show-empty
    >
      <template v-slot:cell(index)="row">{{ row.index + 1 }}.</template>
      <template v-slot:cell(contentId)="row">
        <b-avatar
          v-if="row.value"
          variant="primary"
          class="elevation-2"
          alt="Patient Image"
          :src="viewFile(row.value)"
          square
        ></b-avatar>
      </template>
      <template #cell(dn)="row">
        <span class="narrow-spacing">
          {{ row.value }}
          <span class="small-text text-muted">
            {{ row.item.existDn ? "/" + row.item.existDn : "" }}
          </span>
        </span>
      </template>

      <template v-slot:table-busy>
        <div class="text-center text-primary my-2">
          <b-spinner class="align-middle"></b-spinner>
        </div>
      </template>
      <template v-slot:custom-foot v-if="loadingScroll">
        <b-tr>
          <b-td colspan="12" class="text-center">
            <b-spinner variant="primary"></b-spinner>
          </b-td>
        </b-tr>
      </template>
      <template #empty>
        <div class="text-center text-muted">
          <p>ไม่พบรายชื่อคนไข้ที่ค้นหา</p>
          <b-button size="sm" variant="primary" @click="openCustomerModal"
            >สร้างประวัติใหม่</b-button
          >
        </div>
      </template>
    </b-table>
  </b-modal>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import "@/theme/modal/SearchCustomer.scss";
import moment from "moment";
import { eventBus } from "@/main";
import debounce from "@/utils/debounce";

export default {
  name: "SearchCustomerModal",
  components: {},
  data() {
    return {
      appointment: null,
      searchTable: "",
      debounce: null,
      tableHeader: [
        { key: "index", label: "", class: "narrow-spacing" },
        {
          key: "dn",
          label: "DN./DN.(เดิม)",
          class: "narrow-spacing",
          // sortable: true
        },
        { key: "contentId", label: "รูป", sortable: false },
        {
          key: "firstNameTh",
          label: "ชื่อ",
          sortable: true,
          class: "narrow-spacing",
        },
        {
          key: "lastNameTh",
          label: "นามสกุล",
          sortable: true,
          class: "narrow-spacing",
        },
        {
          key: "dob",
          label: "อายุ",
          formatter: this.calAge,
          class: "narrow-spacing",
        },
        {
          key: "mobile",
          label: "เบอร์โทร",
          formatter: this.mobileFormat,
          class: "narrow-spacing",
        },

        // { key: "personalId", label: "เลขบัตร/passport", sortable: false },
        // { key: 'dob', label: 'อายุ', sortable: false },
      ],
      patientOffset: 0,
      patientLimit: 20,
      loadingScroll: false,
      loadingSearch: false,
      openFrom: null,
      debouncedHandleScroll: null,
      isEveryBranch: false,
    };
  },
  created() {
    this.init();
  },
  computed: {
    ...mapGetters({
      patientList: "modulePatient/getPatientSearchList",
      viewFile: "moduleContent/viewFile",
      getMaxPatient: "modulePatient/getMaxPatient",
    }),
  },
  methods: {
    ...mapActions({
      fetchPatientSearchList: "modulePatient/fetchPatientSearchList",
      fetchPatientSearchScrollList:
        "modulePatient/fetchPatientSearchScrollList",
    }),
    init() {},
    show(appointment = {}, searchValue, openFrom) {
      this.appointment = Object.assign(appointment);
      this.openFrom = openFrom || null;
      this.$refs["SearchCustomerModal"].show();
      this.$nextTick(() => {
        this.$nextTick(() => {
          this.$refs.tableSearchPatient.$el.addEventListener(
            "scroll",
            this.debouncedHandleScroll
          );
          this.searchTable = searchValue;
          this.searchCustomer();
        });
      });
    },
    selectCustomer(customer) {
      if (this.openFrom === "UnnamedPtApt") {
        eventBus.$emit("confirmSwal", {
          title: "เลือกคนไข้",
          message: `เลือกคนไข้ชื่อ "${customer.firstNameTh} ${customer.lastNameTh}" สำหรับนัดหมายนี้`,
          icon: "warning",
          callback: result => {
            if (result.isConfirmed) {
              eventBus.$emit("applyPt", customer);
              this.$refs["SearchCustomerModal"].hide();
            }
          },
        });
      } else if (this.openFrom === "QManage") {
        eventBus.$emit("confirmSwal", {
          title: "เลือกคนไข้",
          message: `เลือกคนไข้ชื่อ "${customer.firstNameTh} ${customer.lastNameTh}" สำหรับนัดหมายนี้`,
          icon: "warning",
          callback: result => {
            if (result.isConfirmed) {
              let event = {
                id: parseInt(this.appointment.id),
                startDt: this.appointment.start,
                endDt: this.appointment.end,
                roomId: parseInt(this.appointment.extendedProps.roomId),
                aptTreatItems: this.appointment.extendedProps.treatmentList,
                rmdId: this.appointment.extendedProps.rmdId,
                doctorUid: this.appointment.extendedProps.doctor,
                note: this.appointment.extendedProps.note,
                patientId: customer.id,
              };
              this.$store.dispatch("moduleAppointment/updateQueueAppointment", {
                event,
                clinicUrl: this.$route.params.clinicUrl,
                branchUrl: this.$route.params.branchUrl,
              });
              this.$refs["SearchCustomerModal"].hide();
            }
          },
        });
      } else {
        this.$refs["SearchCustomerModal"].hide();
        this.$emit(
          "openProfileCustomerModal",
          this.appointment,
          Object.assign({}, customer)
        );
      }
    },
    openCustomerModal() {
      this.$refs["SearchCustomerModal"].hide();
      this.$emit("openCustomerModal");
    },
    searchCustomer() {
      this.loadingSearch = true;
      let keyword = this.searchTable
        ? this.searchTable.trim().replace(/-/g, "")
        : "";
      this.fetchPatientSearchList({
        clinicUrl: this.$route.params.clinicUrl,
        branchUrl: this.$route.params.branchUrl,
        offset: this.patientOffset,
        limit: this.patientLimit,
        isEveryBranch: this.isEveryBranch,
        keyword,
      }).then(() => (this.loadingSearch = false));
    },
    debounceSearchCustomer() {
      this.patientOffset = 0;
      clearTimeout(this.debounce);
      this.debounce = setTimeout(() => {
        this.searchCustomer();
      }, 600);
    },
    handleScroll(event) {
      if (
        event.target.scrollTop >
          event.target.scrollHeight - event.target.offsetHeight - 50 &&
        this.loadingScroll == false &&
        this.getMaxPatient != this.patientList.length
      ) {
        if (this.loadingSearch == false) {
          this.loadingScroll = true;
        }
        this.patientOffset += this.patientLimit;
        this.fetchPatientSearchScrollList({
          search: this.searchTable ? this.searchTable.trim() : "",
          clinicUrl: this.$route.params.clinicUrl,
          branchUrl: this.$route.params.branchUrl,
          offset: this.patientOffset,
          limit: this.patientLimit,
          isEveryBranch: this.isEveryBranch,
        }).then(() => (this.loadingScroll = false));
      }
    },
    defaultValue() {
      this.removeScrollEvent();
      this.patientOffset = 0;
      this.searchTable = "";
      this.openFrom = null;
    },
    removeScrollEvent() {
      this.$refs.tableSearchPatient.$el.removeEventListener(
        "scroll",
        this.debouncedHandleScroll
      );
    },
    focusSearchInput() {
      this.$refs.searchInput.focus();
    },
    mobileFormat(num) {
      if (num) {
        return num.replace(/(\d{1,3})(\d{1,3})?(\d{1,10})?/g, "$1-$2-$3");
      }
    },
    calAge(dateString) {
      if (!dateString) return;
      const date = moment(dateString, "YYYY-MM-DD");
      return moment().diff(date, "years");
    },
  },
  mounted() {
    this.debouncedHandleScroll = debounce(this.handleScroll, 300);
  },
  beforeDestroy() {},
};
</script>

<style></style>
