<template>
  <div class="notification"></div>
</template>

<script>
import moment from "moment";
import { eventBus } from "@/main";
import { mapGetters, mapActions } from "vuex";

const QueueManageRoute = "QueueManage";
const QueueListRoute = "QueueList";
const DashboardRoute = "Dashboard";
const DoctorsTreatingQueueRoute = "DoctorsTreatingQueue";

import Pws from "pws";

// import ReconnectingWebSocket from "reconnecting-websocket";
// const options = {
//   reconnectionDelayGrowFactor: 3, // how fast the reconnection delay grows
//   connectionTimeout: 1000,
//   minUptime: 1000,
//   maxRetries: 10, // maximum number of retries
//   debug: true, // enables debug output
// };

export default {
  name: "Notification",
  data() {
    return {
      modalIds: [
        "PaymentHistoryModal",
        "DrawingTreatmentHistory",
        "NextVisitModal",
      ],
      // isRefreshAllow: true,
      isReconnect: false,

      reconnectAttempts: 0,
      maxReconnectAttempts: 5,
      reconnectTimout: null,
      initialDelay: 500, // 3 seconds
      delayTimeout: null,

      errorAttempts: 0,
      errorMaxAttemps: 5,
      errorTimeout: null,

      interval: null,
      // timeout: null,
      reconnectTimeout: null,

      isFunctionRunning: false,
      lastCallTime: 0,
    };
  },

  mounted() {
    this.connect();
    document.addEventListener("visibilitychange", this.queueReconnect);
  },
  watch: {
    getAccessToken(token, oldToken) {
      this.reconnect();
    },
  },
  destroyed() {
    this.disconnect();
    document.removeEventListener("visibilitychange", this.queueReconnect);
  },
  computed: {
    ...mapGetters({
      userInfo: "moduleUser/getUserInfo",
      authorization: "moduleUser/getAuthorization",
      isManager: "moduleUser/isManager",
      isDoctor: "moduleUser/isDoctor",
      isCounter: "moduleUser/isCounter",
      isHeadCounter: "moduleUser/isHeadCounter",
      isAssistant: "moduleUser/isAssistant",
      getAccessToken: "moduleAuth/getAccessToken",
    }),
    onQueueRoute() {
      return (
        this.$route.name === "QueueManage" || this.$route.name === "QueueList"
      );
    },
    onDashboardRoute() {
      return this.$route.name === "Dashboard";
    },
    onDoctorsTxQRoute() {
      return this.$route.name === "DoctorsTreatingQueue";
    },
  },
  methods: {
    ...mapActions({
      newCheckUserLogin: "moduleUser/newCheckUserLogin",
      updateStateQueueAppointment:
        "moduleAppointment/updateStateQueueAppointment",
      createStateQueueAppointment:
        "moduleAppointment/createStateQueueAppointment",
      deleteStateQueueAppointment:
        "moduleAppointment/deleteStateQueueAppointment",
      createStateDoctorAppointment:
        "moduleAppointment/createStateDoctorAppointment",
      updateStateDoctorAppointment:
        "moduleAppointment/updateStateDoctorAppointment",
      updateStateDoctorCheckInList:
        "moduleAppointment/updateStateDoctorCheckInList",
      deleteStateDoctorAppointment:
        "moduleAppointment/deleteStateDoctorAppointment",
      deleteStateDoctorCheckInList:
        "moduleAppointment/deleteStateDoctorCheckInList",
      fetchDoctorCheckInList: "moduleAppointment/fetchDoctorCheckInList",
      createStateWaitingForPaymentApptList:
        "moduleAppointment/createStateWaitingForPaymentApptList",
      deleteStateWaitingForPaymentApptList:
        "moduleAppointment/deleteStateWaitingForPaymentApptList",
      createStateDoctorCheckInList:
        "moduleAppointment/createStateDoctorCheckInList",

      fetchAllCheckInList: "moduleAppointment/fetchAllCheckInList",

      updateStateAllDoctorsAppointment:
        "moduleAppointment/updateStateAllDoctorsAppointment",
      createStateAllDoctorsAppointment:
        "moduleAppointment/createStateAllDoctorsAppointment",
      deleteStateAllDoctorsAppointment:
        "moduleAppointment/deleteStateAllDoctorsAppointment",
      updateStateAllDoctorsCheckInList:
        "moduleAppointment/updateStateAllDoctorsCheckInList",
      createStateAllDoctorsCheckInList:
        "moduleAppointment/createStateAllDoctorsCheckInList",
      deleteStateAllDoctorsCheckInList:
        "moduleAppointment/deleteStateAllDoctorsCheckInList",
      refreshToken: "moduleAuth/refreshToken",
    }),

    dashboardMessageHandler: function (message) {
      let clinicURL = this.$route.params.clinicUrl;
      let branchURL = this.$route.params.branchUrl;

      const data = JSON.parse(message.data);
      if (data.topic !== "/topic/appointment") return;

      const { content, action } = data;

      const contentUid = content.doctorUid;

      if (contentUid !== this.userInfo.uid) return;
      if (action === "create" || action === "updateTo") {
        this.createStateDoctorAppointment(content);
      } else if (
        action === "update" ||
        action === "move" ||
        action === "confirmation"
      ) {
        this.updateStateDoctorAppointment(content);
        this.updateStateDoctorCheckInList(content);
      } else if (
        action === "checkin" ||
        action === "cancelCheckin" ||
        action === "refer"
      ) {
        this.updateStateDoctorAppointment(content);
        const startDt = moment(content.startDt).format("YYYY-MM-DD");
        if (startDt == moment().format("YYYY-MM-DD")) {
          this.fetchDoctorCheckInList({
            date: startDt,
            clinicUrl: clinicURL,
            branchUrl: branchURL,
            doctorUid: contentUid,
          }).then((res) => {
            if (action === "checkin") {
              eventBus.$emit("blinkNewCheckIn", content);
              this.$parent.showDialogToast("warning", "คนไข้รอเข้าตรวจ");
              eventBus.$emit("playAudio", "waitingForTreatment");
            }
          });
        }
      } else if (action === "delete" || action === "updateFrom") {
        const id = action === "delete" ? content : content.id;
        this.deleteStateDoctorAppointment(id);
        this.deleteStateDoctorCheckInList(id);
      } else if (action == "waitingForPayment") {
        this.updateStateDoctorAppointment(content);
        this.updateStateDoctorCheckInList(content);
        this.deleteStateDoctorCheckInList(content.id);
      } else if (action == "cancelWaitingForPayment") {
        this.updateStateDoctorAppointment(content);
        this.createStateDoctorCheckInList(content);
      } else if (action === "payment") {
        this.updateStateDoctorAppointment(content);
      } else if (action === "waitingForPaymentProcessing") {
        this.updateStateDoctorAppointment(content);
      } else if (action === "waitingForPrintingReceipt") {
        this.updateStateDoctorAppointment(content);
      }
    },
    queueMessageHandler: function (message) {
      const data = JSON.parse(message.data);
      if (data.topic !== "/topic/appointment") return;

      const { content, action } = data;

      if (action === "create") {
        this.createStateQueueAppointment(content);
      } else if (
        action === "update" ||
        action === "move" ||
        action === "checkin" ||
        action === "confirmation" ||
        action === "refer"
      ) {
        this.updateStateQueueAppointment(content);
      } else if (action === "cancelCheckin") {
        this.updateStateQueueAppointment(content);
        this.deleteStateWaitingForPaymentApptList(content.id);
      } else if (action === "delete") {
        const id = content;
        this.deleteStateQueueAppointment(id);
      } else if (action == "waitingForPayment") {
        this.createStateWaitingForPaymentApptList(content);
        this.updateStateQueueAppointment(content);
        eventBus.$emit("blinkNewPayment", content);
        this.$parent.showDialogToast("warning", "มีคนไข้รอชำระเงิน");
        eventBus.$emit("playAudio", "waitingForPayment");
      } else if (action == "cancelWaitingForPayment") {
        this.deleteStateWaitingForPaymentApptList(content.id);
        this.updateStateQueueAppointment(content);
        eventBus.$emit("deleteWaitingForPayment", content);
      } else if (action == "payment") {
        this.deleteStateWaitingForPaymentApptList(content.id);
        this.updateStateQueueAppointment(content);
      } else if (action == "waitingForPaymentProcessing") {
        this.deleteStateWaitingForPaymentApptList(content.id);
        this.updateStateQueueAppointment(content);
      } else if (action === "waitingForPrintingReceipt") {
        this.updateStateQueueAppointment(content);
      }
    },
    doctorsTreatingQueueHandler: function (message) {
      let clinicURL = this.$route.params.clinicUrl;
      let branchURL = this.$route.params.branchUrl;
      const data = JSON.parse(message.data);
      if (data.topic !== "/topic/appointment") return;

      const { content, action } = data;

      if (action === "create" || action === "updateTo") {
        this.createStateAllDoctorsAppointment(content);
      } else if (
        action === "update" ||
        action === "move" ||
        action === "confirmation"
      ) {
        this.updateStateAllDoctorsAppointment(content);
        this.updateStateAllDoctorsCheckInList(content);
      } else if (
        action === "checkin" ||
        action === "cancelCheckin" ||
        action === "refer"
      ) {
        this.updateStateAllDoctorsAppointment(content);
        const startDt = moment(content.startDt).format("YYYY-MM-DD");
        if (startDt == moment().format("YYYY-MM-DD")) {
          this.fetchAllCheckInList({
            date: startDt,
            clinicUrl: clinicURL,
            branchUrl: branchURL,
          }).then((res) => {
            if (action === "checkin") {
              eventBus.$emit("notifyTxPt", content);
            }
          });
        }
      } else if (action === "delete" || action === "updateFrom") {
        const id = action === "delete" ? content : content.id;
        this.deleteStateAllDoctorsAppointment(id);
        this.deleteStateAllDoctorsCheckInList(id);
      } else if (action == "waitingForPayment") {
        this.updateStateAllDoctorsAppointment(content);
        this.deleteStateAllDoctorsCheckInList(content.id);
      } else if (action == "cancelWaitingForPayment") {
        this.updateStateAllDoctorsAppointment(content);
        this.createStateAllDoctorsCheckInList(content);
      } else if (action === "payment") {
        this.updateStateAllDoctorsAppointment(content);
      }
    },
    async reconnect() {
      this.clearReconnectTimeout();
      await this.disconnect();
      if (this.reconnectAttempts < this.maxReconnectAttempts) {
        const delay = this.initialDelay * Math.pow(3, this.reconnectAttempts);
        console.log(`Reconnecting in ${delay}ms`);
        this.delayTimeout = setTimeout(this.connect, delay);
        this.reconnectAttempts++;
      }
    },
    async disconnect() {
      console.log("disconnect");
      if (this.websocketClient) {
        this.clearTimeoutAndInterval();
        this.clearReconnectTimeout();
        this.removeEventListeners();

        await this.websocketClient.close();
        console.log("WebSocket Connection Closed");
      }
    },
    removeEventListeners() {
      this.websocketClient.removeEventListener(
        "open",
        this.handleWebsocketOpen
      );
      this.websocketClient.removeEventListener(
        "message",
        this.handleWebsocketMessage
      );
      this.websocketClient.removeEventListener(
        "message",
        this.dashboardMessageHandler
      );
      this.websocketClient.removeEventListener(
        "message",
        this.queueMessageHandler
      );
      this.websocketClient.removeEventListener(
        "message",
        this.doctorsTreatingQueueHandler
      );
      this.websocketClient.removeEventListener(
        "error",
        this.handleWebsocketError
      );
    },
    fetchData() {
      console.log("Update Appointment Data");
      eventBus.$emit("fetch-queue-data");
    },
    queueReconnect(e) {
      if (document.visibilityState === "visible" && !this.reconnectTimeout) {
        console.log("queueReconnect");
        this.reconnectTimeout = setTimeout(this.reconnect, 5000);
      }
    },
    clearReconnectTimeout() {
      if (this.reconnectTimeout) {
        clearTimeout(this.reconnectTimeout);
        this.reconnectTimeout = null;
      }
      clearTimeout(this.delayTimeout);
      this.delayTimeout = null;
      clearTimeout(this.errorTimeout);
      this.errorTimeout = null;
    },

    clearTimeoutAndInterval() {
      clearInterval(this.interval);
      this.interval = null;
      // this.clearPingTimeout();
    },
    // clearPingTimeout() {
    //   if (this.timeout) clearTimeout(this.timeout);
    //   this.timeout = null;
    // },

    ping() {
      if (this.interval) return;
      this.interval = setInterval(() => {
        // Send a message to the WebSocket server
        const message = JSON.stringify({
          data: {
            type: "ping",
            message: "ping pong",
          },
        });
        console.log("Ping");
        this.websocketClient.send(message);
        // this.queueReconnect();
      }, 4 * 60 * 1000);
    },

    connect() {
      try {
        let clinicURL = this.$route.params.clinicUrl;
        let branchURL = this.$route.params.branchUrl;
        const topic = "appointment";
        const urlParams = new URLSearchParams({
          clinicURL,
          branchURL,
          topic,
          authorization: `Bearer ${localStorage.getItem("access_token")}`,
        });
        switch (this.$route.name) {
          case DashboardRoute:
            urlParams.append("doctorUID", this.authorization.uid);
            break;
        }
        const wsURL = new URL(process.env.VUE_APP_WS_URL);
        wsURL.search = urlParams;
        this.websocketClient = new Pws(
          wsURL.toString(),
          {
            pingTimeout: 5 * 60 * 1000, // Reconnect if no message received in 30s.
          }
          // [],
          // options
        );

        this.websocketClient.addEventListener("open", this.handleWebsocketOpen);
        this.websocketClient.addEventListener(
          "message",
          this.handleWebsocketMessage
        );
        switch (this.$route.name) {
          case DashboardRoute:
            this.websocketClient.addEventListener(
              "message",
              this.dashboardMessageHandler
            );
            break;
          case QueueListRoute:
          case QueueManageRoute:
            this.websocketClient.addEventListener(
              "message",
              this.queueMessageHandler
            );
            break;
          case DoctorsTreatingQueueRoute:
            this.websocketClient.addEventListener(
              "message",
              this.doctorsTreatingQueueHandler
            );
            break;
          default:
          // do nothing
        }

        this.websocketClient.addEventListener(
          "error",
          this.handleWebsocketError
        );

        this.ping();
      } catch (error) {
        console.error("WebSocket error with :", error);
      }
    },
    handleWebsocketOpen() {
      console.log("WebSocket opened");
      if (this.isReconnect) {
        this.fetchData();
      }
      this.clearReconnectTimeout();
      this.isReconnect = true;
      this.reconnectAttempts = 0;
      this.errorAttempts = 0;
    },
    handleWebsocketMessage(e) {
      this.clearReconnectTimeout();
      const data = JSON.parse(e.data);
      console.log("WebSocket message : ", data);
      if (data.statusCode === 401) this.refreshToken();
      if (data.message === "Internal server error") this.queueReconnect();
    },
    handleWebsocketError(e) {
      console.log("WebSocket error message : ", e);

      if (this.errorAttempts < this.errorMaxAttemps) {
        const delay = this.initialDelay * Math.pow(3, this.errorAttempts);
        console.log(`error connection attempt in ${delay}ms`);
        this.errorTimeout = setTimeout(this.refreshToken, delay);
        // setTimeout(this.refreshTokenOnceAMinute, delay);
        this.errorAttempts++;
      }
    },
    refreshTokenOnceAMinute() {
      if (this.isFunctionRunning) {
        console.log("Function is already running.");
        return;
      }

      const currentTime = Date.now();

      if (currentTime - this.lastCallTime >= 60000) {
        // 60000 milliseconds = 1 minute
        this.isFunctionRunning = true;
        this.lastCallTime = currentTime;

        // Your function's code here
        console.log("Refresh Token!");
        this.refreshToken();

        // Reset the flag after some delay (e.g., 5 seconds) to allow it to run again
        setTimeout(() => {
          this.isFunctionRunning = false;
        }, 5000); // 5000 milliseconds = 5 seconds
      } else {
        console.log("Function can only be called once per minute.");
      }
    },
  },
};
</script>

<style></style>
