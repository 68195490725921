<template>
  <div class="row">
    <div
      class="col-12 col-lg-2 divider-lg-right divider-bottom divider-lg-bottom-0 mb-3 mb-lg-0"
    >
      <PatientProfile
        :patient="patient"
        button-name="ข้อมูลติดต่อ"
        :button-click="
          () => {
            editCustomer(patient);
          }
        "
      />
    </div>
    <div class="col-lg-10">
      <div class="tab-pane p-0" id="paymentHix" role="tabpanel">
        <b-tabs class="p-0" content-class="pt-2" pills small>
          <b-tab title="รายการรักษา" active>
            <b-row>
              <b-col cols="12" md="6">
                <b-form-group
                  class="mb-1"
                  label="ค้นหา:"
                  label-cols-sm="3"
                  label-align-sm="right"
                  label-class="d-none d-sm-block"
                >
                  <b-input-group class="mb-1">
                    <b-form-input
                      v-model="itemHxfilter"
                      type="search"
                      placeholder="พิมพ์ค้นหาจากรายการรักษา"
                    ></b-form-input>
                  </b-input-group>
                </b-form-group>
              </b-col>
              <b-col cols="12" md="6">
                <date-picker
                  class="mb-2"
                  v-model="dateRange"
                  type="date"
                  range
                  format="DD MMM YY"
                  :shortcuts="dateRangeShortcuts"
                  :disabled-date="notAfterToday"
                  placeholder="เลือกวันที่ต้องการค้นหา"
                  input-class="form-control"
                >
                </date-picker>
              </b-col>
            </b-row>
            <b-card no-body>
              <b-table
                class="mb-1"
                small
                sort-icon-left
                hover
                head-variant="light"
                :busy="isTableBusy"
                :items="filteredItemsList"
                :fields="itemsTableFields"
                sort-by="creationDt"
                :sort-desc="true"
                :responsive="true"
                :filter="itemHxfilter"
                :filter-included-fields="['itemName']"
                show-empty
                @row-dblclicked="showReceipt"
              >
                <template #cell(index)="row">{{ row.index + 1 }}.</template>
                <template #cell(creationDt)="row">{{
                  formatDate(row.value)
                }}</template>
                <template #cell(paid)="row"
                  >{{ row.value
                  }}{{
                    row.item.overdue === 0 ? "" : `(${row.item.overdue})`
                  }}</template
                >
                <!-- <template #cell(price)="row"
            >{{ row.value
            }}{{
              row.item.finalDiscount === 0 ? "" : `(${row.item.finalDiscount})`
            }}</template
          > -->
                <template #empty="">
                  <p class="text-center text-muted my-2">ไม่มีข้อมูลให้แสดง</p>
                </template>
                <template #table-busy>
                  <div class="text-center text-primary my-2">
                    <b-spinner class="align-middle"></b-spinner>
                  </div>
                </template>
                <template #table-caption>Double click เพื่อดูใบเสร็จ</template>
              </b-table>
            </b-card>
          </b-tab>
          <b-tab title="ใบเสร็จ">
            <b-row>
              <b-col cols="12" md="6">
                <b-form-group
                  class="mb-1"
                  label="ค้นหา:"
                  label-cols-sm="3"
                  label-align-sm="right"
                  label-class="d-none d-sm-block"
                >
                  <b-input-group>
                    <b-form-input
                      v-model="receiptHxFilter"
                      type="search"
                      placeholder="พิมพ์ค้นหาจากรายการรักษา"
                    ></b-form-input>
                  </b-input-group>
                </b-form-group>
              </b-col>
              <b-col cols="12" md="6">
                <date-picker
                  class="mb-2"
                  v-model="dateRange"
                  type="date"
                  range
                  format="DD MMM YY"
                  :shortcuts="dateRangeShortcuts"
                  :disabled-date="notAfterToday"
                  placeholder="เลือกวันที่ต้องการค้นหา"
                  input-class="form-control"
                >
                </date-picker>
              </b-col>
            </b-row>
            <b-card no-body>
              <b-table
                class="mb-0"
                small
                sort-icon-left
                head-variant="light"
                hover
                :busy="isTableBusy"
                :items="filteredReceiptsList"
                :fields="receiptsTableFields"
                sort-by="creationDt"
                :sort-desc="true"
                :responsive="true"
                show-empty
                :filter="receiptHxFilter"
                :filter-included-fields="['receiptNo']"
                @row-dblclicked="showReceipt"
              >
                <template #cell(index)="row">{{ row.index + 1 }}.</template>
                <template #cell(creationDt)="row">{{
                  formatDate(row.value)
                }}</template>
                <template #cell(paid)="row"
                  >{{ row.value
                  }}{{
                    row.item.overdue === 0 ? "" : `(${row.item.overdue})`
                  }}</template
                >
                <template #empty="">
                  <p class="text-center text-muted my-2">ไม่มีข้อมูลให้แสดง</p>
                </template>
                <template #table-busy>
                  <div class="text-center text-primary my-2">
                    <b-spinner class="align-middle"></b-spinner>
                  </div>
                </template>
                <template #table-caption>Double click เพื่อดูใบเสร็จ</template>
              </b-table></b-card
            >
          </b-tab>
        </b-tabs>
      </div>
    </div>
    <PaymentReceiptPreviewModal
      ref="paymentReceiptPreviewModal"
    ></PaymentReceiptPreviewModal>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import moment from "moment";
import { eventBus } from "@/main";
import PaymentReceiptPreviewModal from "@/components/modal/PaymentReceiptPreviewModal";

export default {
  name: "PatientPaymenHistory",
  components: {
    PaymentReceiptPreviewModal,
  },
  props: ["patient"],
  data() {
    return {
      itemsList: [],
      receiptsList: [],
      isTableBusy: false,

      itemsTableFields: [
        {
          key: "index",
          label: "",
        },
        {
          key: "creationDt",
          label: "วันที่",
        },
        {
          key: "itemName",
          label: "รายการรักษา",
        },
        {
          key: "price",
          label: "ค่ารักษา",
          class: "text-right",
        },
        {
          key: "finalDiscount",
          label: "ส่วนลด",
          class: "text-right",
        },
        {
          key: "amount",
          label: "สุทธิ",
          class: "text-right",
        },
        {
          key: "paid",
          label: "ชำระ(ค้าง)",
          class: "text-right",
        },
      ],
      receiptsTableFields: [
        {
          key: "index",
          label: "",
        },
        {
          key: "creationDt",
          label: "วันที่",
        },
        {
          key: "receiptNo",
          label: "เลขที่ใบเสร็จ",
        },
        {
          key: "amount",
          label: "ค่ารักษาสุทธิ",
          class: "text-right",
        },
        {
          key: "paid",
          label: "ชำระ(ค้าง)",
          class: "text-right",
        },
      ],

      //filter
      itemHxfilter: "",
      receiptHxFilter: "",

      dateRange: [],
    };
  },
  computed: {
    ...mapGetters({
      dateRangeShortcuts: "moduleMaster/getDateRangeShortcuts",
    }),

    filteredItemsList() {
      let list = this.itemsList;

      if (this.dateRange[0] && this.dateRange[1]) {
        list = list.filter((item) =>
          moment(item.creationDt)
            .locale("th")
            .isBetween(
              moment(this.dateRange[0]),
              moment(this.dateRange[1]),
              "day",
              "[]"
            )
        );
      }
      return list;
    },
    filteredReceiptsList() {
      let list = this.receiptsList;

      if (this.dateRange[0] && this.dateRange[1]) {
        list = list.filter((item) =>
          moment(item.creationDt)
            .locale("th")
            .isBetween(
              moment(this.dateRange[0]),
              moment(this.dateRange[1]),
              "day",
              "[]"
            )
        );
      }
      return list;
    },
  },
  created() {},
  methods: {
    ...mapActions({
      fetchPatientPaymentItem: "moduleReport/fetchPatientPaymentItem",
      fetchPatientPaymentReceipt: "moduleReport/fetchPatientPaymentReceipt",
    }),
    fetchPaymentHx() {
      this.isTableBusy = true;
      Promise.all([
        this.fetchPatientPaymentItem({
          clinicUrl: this.$route.params.clinicUrl,
          branchUrl: this.$route.params.branchUrl,
          patientID: this.patient.id,
        }),
        this.fetchPatientPaymentReceipt({
          clinicUrl: this.$route.params.clinicUrl,
          branchUrl: this.$route.params.branchUrl,
          patientID: this.patient.id,
        }),
      ])
        .then(([res1, res2]) => {
          this.itemsList = res1;
          this.receiptsList = res2;
        })
        .finally(() => {
          this.isTableBusy = false;
        });
    },

    formatDate(date) {
      if (date) {
        return moment(date).locale("th").format("D MMM YY");
      } else {
        return "";
      }
    },
    editCustomer(patient) {
      this.$emit(
        "openCustomerModal",
        Object.assign({}, patient),
        this.appointment
      );
    },
    notAfterToday(date) {
      return date > new Date(new Date().setHours(0, 0, 0, 0));
    },
    setDefaultValuePaymentHx() {
      this.itemsList = [];
      this.receiptsList = [];
    },
    showReceipt(row) {
      this.$refs.paymentReceiptPreviewModal.fetchAndShow(row);
    },
  },
  mounted() {
    this.fetchPaymentHx();
    eventBus.$on(
      "setDefaultValueProfileCustomer",
      this.setDefaultValuePaymentHx
    );
  },
};
</script>

<style>
</style>