<template>
  <b-modal
    ref="patientOrthoPkgModal"
    title="ประวัติแพ็กเกจจัดฟัน"
    size="lg fluid"
    hide-footer
    body-class="small-text"
    @hidden="defaultValue"
  >
    <b-row>
      <b-col
        ><b-form-group
          class="mb-4"
          label-cols="4"
          label-cols-lg="3"
          label-size="sm"
          label="ชื่อแพ็กเกจ:"
          label-align-sm="right"
        >
          <b-form-input
            class="mb-2"
            size="sm"
            :value="packageDetail.nameTh"
            disabled
          ></b-form-input>
          <b-form-input
            size="sm"
            :value="packageDetail.nameEn"
            disabled
          ></b-form-input
        ></b-form-group>
        <b-form-group
          label-cols="4"
          label-cols-lg="3"
          label-size="sm"
          label="วันที่เริ่ม:"
          label-align-sm="right"
        >
          <b-form-input
            size="sm"
            :value="formatDate(packageDetail.creationDt)"
            disabled
          ></b-form-input> </b-form-group
      ></b-col>
      <b-col>
        <b-form-group
          label-cols="4"
          label-cols-lg="3"
          label-size="sm"
          label="DF:"
          label-align-sm="right"
        >
          <b-form-input
            size="sm"
            :value="packageDetail.df"
            disabled
          ></b-form-input>
        </b-form-group>
        <b-form-group
          label-cols="4"
          label-cols-lg="3"
          label-size="sm"
          label="ราคารวม:"
          label-align-sm="right"
        >
          <b-form-input size="sm" :value="totalPrice" disabled></b-form-input>
        </b-form-group>
        <b-form-group
          label-cols="4"
          label-cols-lg="3"
          label-size="sm"
          label="คงเหลือ:"
          label-align-sm="right"
        >
          <b-form-input
            size="sm"
            :value="remained"
            disabled
          ></b-form-input> </b-form-group
      ></b-col>
    </b-row>

    <b-table :fields="header" :items="packageItems" small>
      <template #cell(index)="row"> {{ row.index + 1 }}. </template>
    </b-table>
    <Loading v-if="isLoading"></Loading>
  </b-modal>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import moment from "moment";

import Loading from "@/components/Loading";

export default {
  name: "PatientOrthoPkgModal",
  components: { Loading },
  props: ["patientId"],
  data() {
    return {
      packageDetail: {},
      packageItems: [],
      header: [
        {
          key: "index",
          label: "",
        },
        { key: "nameTh", label: "รายการ" },
        { key: "nameEn", label: "รายการ(Eng)" },
        { key: "price", label: "ราคาตามแพ็กเกจ", class: "text-right" },
        {
          key: "balance",
          label: "ราคาคิดจริง",
          formatter: this.calBalance,
          class: "text-right",
        },
        {
          key: "creationDt",
          label: "ชำระเงินเมื่อ",
          formatter: this.formatDate,
        },
        {
          key: "actionUid",
          label: "แพทย์",
          formatter: this.handleUserName,
        },
      ],
      isLoading: false,
    };
  },
  computed: {
    ...mapGetters({
      getOrthoPackageUserBy: "moduleTreatment/getOrthoPackageUserBy",
      getPatientOrthoPackage: "moduleTreatment/getPatientOrthoPackage",
      getPatientOrthoPackageItems:
        "moduleTreatment/getPatientOrthoPackageItems",
      getUserListBy: "moduleUser/getUserListBy",
    }),
    totalPrice() {
      let price = 0;
      if (!this.getPatientOrthoPackageItems) return price;
      this.getPatientOrthoPackageItems.forEach((packageItem) => {
        price += parseFloat(packageItem.price);
      });
      return price;
    },
    remained() {
      let remain = 0;
      if (!this.getPatientOrthoPackageItems) return remain;
      this.getPatientOrthoPackageItems.forEach((packageItem) => {
        if (packageItem.balance == null) {
          remain += parseFloat(packageItem.price);
        } else {
          remain += parseFloat(packageItem.balance);
        }
      });
      return remain;
    },
  },
  methods: {
    ...mapActions({
      fetchOrthoPackageUserList: "moduleTreatment/fetchOrthoPackageUserList",
      fetchPatientOrthoPackage: "moduleTreatment/fetchPatientOrthoPackage",
      fetchOrthoPackageItemActiveList:
        "moduleTreatment/fetchOrthoPackageItemActiveList",
      getOrthoPackageUserById: "moduleTreatment/getOrthoPackageUserById",
    }),
    show() {
      if (this.getPatientOrthoPackage?.packageId) {
        this.$refs.patientOrthoPkgModal.show();
        this.fetchPatientOrthoPackageDetail();
      }
    },

    async fetchPatientOrthoPackageDetail() {
      try {
        this.isLoading = true;
        await Promise.all([
          this.getOrthoPackageUserById({
            clinicUrl: this.$route.params.clinicUrl,
            branchUrl: this.$route.params.branchUrl,
            id: this.getPatientOrthoPackage.packageId,
          }),
          this.fetchOrthoPackageItemActiveList({
            clinicUrl: this.$route.params.clinicUrl,
            branchUrl: this.$route.params.branchUrl,
            patientId: this.patientId,
          }),
        ]).then(async ([pkgDetail, pkgItems]) => {
          this.packageDetail = pkgDetail;
          this.packageItems = pkgItems;
        });
      } catch (err) {
        console.error(err);
      } finally {
        this.isLoading = false;
      }
    },
    calBalance(value, key, item) {
      if (value === null) return;
      return item.price - value;
    },
    formatDate(date) {
      if (date) {
        return moment(date).locale("th").format("ll");
      }
      return "";
    },
    handleUserName(id) {
      return this.getUserListBy(id, "uid")?.fullName || "";
    },
    defaultValue() {
      this.packageDetail = {};
      this.packageItems = [];
    },
  },
};
</script>

<style>
</style>