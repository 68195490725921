<template>
  <b-card
    id="display-draw-note-card"
    no-body
    class="component-display-draw-note"
    border-variant="primary"
  >
    <div v-show="!isItemSelected" class="text-center text-muted m-3">
      กรุณาเลือกรายการรักษา
    </div>
    <div v-show="isItemSelected">
      <b-collapse v-model="hideStage">
        <div class="float-left m-3">
          <span v-if="lines.length === 0" class="text-muted"
            >ไม่มีบันทึกการเขียน</span
          >
          <span v-else class="text-info">
            มีบันทึกการเขียน กรุณาขยายเพื่อดูข้อมูล
            <i class="fas fa-arrow-right"></i>
          </span>
        </div>

        <b-button
          class="float-right m-2"
          variant="outline-primary"
          size="sm"
          @click="toggleCollapse"
        >
          <i class="fas fa-angle-double-down" />
        </b-button>
      </b-collapse>
      <b-collapse v-model="visible" @shown="setupStage">
        <div class="tl-absolute">
          <span class="description p-1 mr-2 bg-info rounded"
            >ใช้สองนิ้วเพื่อลาก และซูม</span
          >

          <!-- <b-button
            class="mr-2"
            variant="outline-primary"
            size="sm"
            :disabled="btnDisabled"
            @click="openDrawingTxHxModal"
          >
            <i class="fas fa-edit"></i>
          </b-button> -->
          <b-button
            class
            variant="outline-primary"
            size="sm"
            @click="toggleCollapse"
          >
            <i class="fas fa-angle-double-up" />
          </b-button>
        </div>

        <v-stage
          id="display-draw-note-stage"
          ref="stage"
          :config="configKonva"
          @touchmove="startMove"
          @touchend="endMove"
        >
          <!-- <v-layer ref="background"> </v-layer> -->
          <v-layer ref="layer">
            <!-- <v-image
              :config="{
                image: image,
              }"
            />-->
            <v-rect :config="configBackground"></v-rect>

            <v-text :config="configText"></v-text>
            <v-line
              v-for="line in lines"
              :key="line.id"
              :config="line"
            ></v-line>
            <!-- <v-rect :config="configForeground"></v-rect> -->
          </v-layer>
        </v-stage>
      </b-collapse>
    </div>
  </b-card>
</template>

<script>
import { mapGetters, mapActions } from "vuex";

// import Vue from "vue";
// import VueKonva from "vue-konva";
// Vue.use(VueKonva);

import moment from "moment";
import { eventBus } from "@/main";

import noteTemplate from "@/assets/img/noteTemplate.jpeg";

export default {
  name: "DisplayDrawNoter",

  components: {},

  props: ["drawNote", "selectedItem"],
  data() {
    return {
      configKonva: {
        width: 720,
        height: 960,
      },

      configBackground: {
        x: 0,
        y: 0,
        width: 720,
        height: 960,
        fillPatternImage: null,
        preventDefault: false,
      },
      configText: {
        x: 15,
        y: 10,
        fontSize: 14,
        lineHeight: 1.5,
        fill: "#3D556E",
        text: this.drawNote?.text || "ไม่มึข้อมูลบันทึก",
      },
      // configForeground: {
      //   x: 0,
      //   y: 0,
      //   width: 720,
      //   height: 960,
      // },
      lines: this.drawNote?.lines || [],

      image: null,

      //Konva objects
      stage: null,

      lastCenter: null,
      lastDist: 0,
      visible: true,
    };
  },
  watch: {},
  created() {},
  computed: {
    ...mapGetters({
      userInfo: "moduleUser/getUserInfo",
      isDoctor: "moduleUser/isDoctor",
    }),
    isItemSelected() {
      return this.selectedItem?.treatId || this.selectedItem?.id;
    },
    btnDisabled() {
      return !(
        (
          this.selectedItem?.treatId &&
          this.isDoctor &&
          moment().diff(this.selectedItem.creationDt, "days") <= 2
        )
        // &&  this.selectedItem?.items
        //   .map(i => i.actionUid)
        //   .includes(this.userInfo.uid)
      );
    },
    hideStage: {
      get() {
        return !this.visible;
      },
      set(val) {},
    },
  },
  methods: {
    //zoom
    getDistance(p1, p2) {
      return Math.sqrt(Math.pow(p2.x - p1.x, 2) + Math.pow(p2.y - p1.y, 2));
    },
    getCenter(p1, p2) {
      return {
        x: (p1.x + p2.x) / 2,
        y: (p1.y + p2.y) / 2,
      };
    },

    startMove(e) {
      var touch1 = e.evt.touches[0];
      var touch2 = e.evt.touches[1];

      if (touch1 && touch2) {
        e.evt.preventDefault();

        // if the stage was under Konva's drag&drop
        // we need to stop it, and implement our own pan logic with two pointers
        if (this.stage.isDragging()) {
          this.stage.stopDrag();
        }

        var p1 = {
          x: touch1.clientX,
          y: touch1.clientY,
        };
        var p2 = {
          x: touch2.clientX,
          y: touch2.clientY,
        };

        if (!this.lastCenter) {
          this.lastCenter = this.getCenter(p1, p2);
          return;
        }
        var newCenter = this.getCenter(p1, p2);

        var dist = this.getDistance(p1, p2);

        if (!this.lastDist) {
          this.lastDist = dist;
        }

        // local coordinates of center point
        var pointTo = {
          x: (newCenter.x - this.stage.x()) / this.stage.scaleX(),
          y: (newCenter.y - this.stage.y()) / this.stage.scaleX(),
        };

        var scale = (this.stage.scaleX() * (dist / this.lastDist)) / 1.0001;

        this.stage.scaleX(scale);
        this.stage.scaleY(scale);

        // calculate new position of the stage
        var dx = newCenter.x - this.lastCenter.x;
        var dy = newCenter.y - this.lastCenter.y;

        var newPos = {
          x: newCenter.x - pointTo.x * scale + dx,
          y: newCenter.y - pointTo.y * scale + dy,
        };

        this.stage.position(newPos);
        this.stage.batchDraw();

        this.lastDist = dist;
        this.lastCenter = newCenter;
      }
    },
    endMove(e) {
      this.lastDist = 0;
      this.lastCenter = null;
    },

    // handleWheel(e) {
    //   e.evt.preventDefault();
    //   var scaleBy = 1.1;
    //   var oldScale = this.stage.scaleX();
    //   var pointer = this.stage.getPointerPosition();
    //   var mousePointTo = {
    //     x: (pointer.x - this.stage.x()) / oldScale,
    //     y: (pointer.y - this.stage.y()) / oldScale,
    //   };
    //   var newScale = e.evt.deltaY > 0 ? oldScale * scaleBy : oldScale / scaleBy;
    //   this.stage.scale({ x: newScale, y: newScale });
    //   var newPos = {
    //     x: pointer.x - mousePointTo.x * newScale,
    //     y: pointer.y - mousePointTo.y * newScale,
    //   };
    //   this.stage.position(newPos);
    //   this.stage.batchDraw();
    // },

    //export file
    exportToPNG() {
      if (this.lines.length === 0) {
        this.$refs.Dialog.showAlertInfo("ไม่มีข้อมูลให้บันทึก");
        return;
      } else {
        let dataURL = this.stage.toDataURL({
          mimeType: "image/png",
          pixelRatio: 2,
        });
        this.downloadURI(dataURL, "EMR.png");
      }
    },
    downloadURI(uri, name) {
      let link = document.createElement("a");
      link.download = name;
      link.href = uri;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    },

    defaultValue() {
      this.lines = [];
      this.configText = {
        x: 15,
        y: 10,
        text: "",
        fontSize: 14,
        lineHeight: 1.5,
        fill: "#3D556E",
      };
      this.selectedItem = {};
      this.aptDraftDisplay = {};

      this.stage.position({ x: 0, y: 0 });
      this.stage.scaleX(1);
      this.stage.scaleY(1);
    },
    async resetDrawScale() {
      await this.$nextTick();
      await this.$nextTick();
      if (!this.stage.content?.offsetParent?.offsetWidth) return;
      this.stage.position({ x: 0, y: 0 });
      this.stage.scaleX(1);
      let scale =
        this.stage.scaleX() *
        (this.stage.content.offsetParent.offsetWidth / 720);
      this.stage.scaleX(scale);
      this.stage.scaleY(scale);
      this.stage.batchDraw();
      document.getElementById("display-draw-note-stage").style.height = `${
        960 * scale
      }px`;
    },
    assignDrawNote(e) {
      if (e?.lines.length > 0) {
        this.configText.text = e.text || "";
        this.lines = [...e.lines];
      } else {
        this.configText.text = "ไม่มีข้อมูล";
        this.lines = [];
      }
      this.stage.batchDraw();
    },
    // openDrawingTxHxModal() {
    //   this.$parent.openDrawingTxHxModal();
    // },
    async setupStage() {
      await this.$nextTick();
      this.resetDrawScale();
    },
    toggleCollapse() {
      this.visible = !this.visible;
      localStorage.setItem("displayDrawNote", this.visible);
    },
  },

  async mounted() {
    await this.$nextTick();
    this.stage = this.$refs.stage.getNode();
    // this.stage.height(960);
    // this.stage.width(720);
    const image = new window.Image();
    image.src = noteTemplate;
    image.onload = () => {
      this.configBackground.fillPatternImage = image;
    };
    await this.$nextTick();
    localStorage.getItem("displayDrawNote") === null
      ? (this.visible = true)
      : (this.visible = localStorage.getItem("displayDrawNote") === "true");

    // this.resetDrawScale();

    eventBus.$on("updateDrawNote", this.assignDrawNote);
    
  },
  beforeDestroy() {
    // ลด memory leak
    this.stage.height(1);
    this.stage.width(1);
    this.stage = null;
  },
  destroyed() {},
};
</script>

<style>
#display-draw-note-card {
  overflow: hidden;
}
#display-draw-note-stage {
  overflow: hidden;
}
.tl-absolute {
  position: absolute;
  top: 0.5rem;
  right: 0.5rem;
  z-index: 1;
}
</style>