<template>
  <div>
    <b-row id="aptHxFilter">
      <b-col cols="12" sm="8">
        <b-form-group
          class="mb-1"
          label="ค้นหา:"
          label-cols-sm="3"
          label-align-sm="right"
          label-for="filterInput"
          label-class="d-none d-sm-block"
        >
          <b-input-group>
            <b-form-input
              v-model="filterAptHx"
              type="search"
              id="filterTxHxInputTxHx"
              placeholder="พิมพ์เพื่อค้นหา"
            ></b-form-input>
          </b-input-group>
        </b-form-group>
      </b-col>
      <b-col cols="12" sm="4">
        <b-button class="mb-2" block v-b-toggle.advanceFilter>
          ค้นหาโดยละเอียด
          <i class="fas fa-chevron-down"></i>
        </b-button>
      </b-col>
    </b-row>
    <b-collapse id="advanceFilter" class="mt-2">
      <b-card bg-variant="light">
        <b-form-group
          label-cols-lg="3"
          label="ค้นหาโดยละเอียด"
          label-class="pt-0"
          label-size="lg"
          class="mb-0"
        >
          <date-picker
            v-model="dateRangeAptHx"
            type="date"
            range
            format="DD MMM YY"
            :shortcuts="dateRangeShortcuts"
            placeholder="เลือกวันที่ต้องการค้นหา"
            input-class="form-control"
          ></date-picker>

          <b-form-group
            label-cols-sm="3"
            label="ค้นหาจาก:"
            label-align-sm="right"
            description="ถ้าไม่เลือก จะค้นหาจากข้อมูลทั้งหมด"
            class="mb-0"
          >
            <b-form-checkbox-group v-model="filterOnAptHx" class="mt-2">
              <b-form-checkbox value="doctor">แพทย์</b-form-checkbox>
              <b-form-checkbox value="title">รายการนัดหมาย</b-form-checkbox>
            </b-form-checkbox-group>
          </b-form-group>
        </b-form-group>
      </b-card>
    </b-collapse>
    <b-card no-body>
      <b-table
        class="mb-0"
        sort-icon-left
        :busy="isBusyAptHx"
        :items="filteredAptHxList"
        :fields="mode === 'unnamed' ? unnamedFields : tableApptHistory"
        :filter="filterAptHx"
        sort-by="date"
        :sort-desc="true"
        head-variant="light"
        small
        responsive
        hover
      >
        <template v-slot:cell(date)="row">
          {{ formatDate(row.item.date) }}
        </template>
        <template v-slot:cell(time)="row">
          {{ formatTime(row.item.date) }}
        </template>
        <template v-slot:row-details="row">
          <b-card>{{ row.item.note }}</b-card>
        </template>
        <template v-slot:cell(checkinStatus)="row">
          <span
            :class="{
              onTime: row.value == 1,
              early: row.value == 2,
              late: row.value == 3,
              walkIn: row.value == 4,
            }"
            >{{ row.item.checkinStatusName }}</span
          >
        </template>
        <template v-slot:table-busy>
          <div class="text-center text-primary my-2">
            <b-spinner class="align-middle"></b-spinner>
          </div>
        </template>
        <template v-if="onQueueManageRoute" #cell(button)="row">
          <b-button
            class="show-when-hovered"
            size="xs"
            variant="outline-info"
            @click="toApt(row.item)"
          >
            <i class="fas fa-external-link-square-alt"></i>
          </b-button>
        </template>
      </b-table>
    </b-card>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import moment from "moment";
import { eventBus } from "@/main";

export default {
  name: "AppointmentHistoryTable",
  props: ["patient", "mode"],
  data() {
    return {
      isBusyAptHx: false,
      filterAptHx: null,
      filterOnAptHx: [],
      dateRangeAptHx: {},
      tableApptHistory: [
        { key: "date", label: "วันที่", sortable: true },
        { key: "time", label: "เวลา" },
        {
          key: "doctor",
          label: "แพทย์",
          class: "narrow-spacing",
          sortable: true,
        },
        {
          key: "title",
          label: "รายการนัดหมาย",
          class: "narrow-spacing",
        },
        {
          key: "note",
          label: "โน๊ตเพิ่มเติม",
          class: "narrow-spacing",
        },
        {
          key: "aptStatus",
          label: "สถานะนัดหมาย",
          class: "narrow-spacing",
        },
        {
          key: "checkinStatus",
          label: "สถานะเวลามาถึง",
          class: "narrow-spacing",
        },
        { key: "button", label: "" },
      ],
      unnamedFields: [
        { key: "date", label: "วันที่", sortable: true },
        { key: "time", label: "เวลา" },
        {
          key: "doctor",
          label: "แพทย์",
          class: "narrow-spacing",
          sortable: true,
        },
        {
          key: "title",
          label: "รายการนัดหมาย",
          class: "narrow-spacing",
        },
        {
          key: "note",
          label: "โน๊ตเพิ่มเติม",
          class: "narrow-spacing",
        },
        {
          key: "aptStatus",
          label: "สถานะนัดหมาย",
          class: "narrow-spacing",
        },
        { key: "button", label: "" },
      ],
    };
  },
  computed: {
    ...mapGetters({
      getAptHxTableList: "moduleAppointment/getAptHxTableList",
      dateRangeShortcuts: "moduleMaster/getDateRangeShortcuts",
    }),
    filteredAptHxList() {
      let list = this.getAptHxTableList;
      if (this.dateRangeAptHx[0] && this.dateRangeAptHx[1]) {
        list = list.filter((item) =>
          moment(item.date)
            .locale("th")
            .isBetween(
              moment(this.dateRangeAptHx[0]),
              moment(this.dateRangeAptHx[1]),
              "day",
              "[]"
            )
        );
      }

      //assign _rowVariant
      list = list.map((apt) => {
        let variant;
        if (moment(apt.date).isBefore(moment(), "day")) {
          variant = ""; //อดีต
        } else if (moment(apt.date).isSame(moment(), "day")) {
          variant = "info"; //วันนี้
        } else {
          variant = "lightGreen"; //อนาคต
        }
        apt._rowVariant = variant;
        return apt;
      });
      return list;
      //   return [];
    },
    onQueueManageRoute() {
      return this.$route.name === "QueueManage";
    },
  },
  methods: {
    ...mapActions({
      fetchAppointmentHistoryList:
        "moduleAppointment/fetchAppointmentHistoryList",
    }),
    async fetchAppointmentHistory() {
      this.isBusyAptHx = true;

      let clinicUrl = this.$route.params.clinicUrl;
      let branchUrl = this.$route.params.branchUrl;
      let event = {
        clinicUrl,
        branchUrl,
        patientId: this.patient.id,
      };
      await this.fetchAppointmentHistoryList(event).then(() => {
        this.isBusyAptHx = false;
      });
    },
    toApt(e) {
      eventBus.$emit("blinkAptId", e.id);
      eventBus.$emit("goToDateTime", e.date);
      this.$bvModal.hide("ProfileCustomerModal");
      this.$bvModal.hide("EventModal");
    },
    formatDate(date) {
      if (date) {
        return moment(date).locale("th").format("D MMM YY");
      } else {
        return "";
      }
    },
    formatTime(date) {
      if (date) {
        return moment(date).locale("th").format("HH:mm");
      } else {
        return "";
      }
    },
  },
  async mounted() {
    await this.$nextTick();
    this.fetchAppointmentHistory();
  },
};
</script>

<style>
</style>