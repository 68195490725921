<template>
  <div>
    <b-button
      class="p-1"
      v-if="isDisplay"
      variant="outline-info"
      size="sm"
      :disabled="disabled"
      @click="openHealthLinkWindow"
    >
      <img
        style="width: auto; height: 22px; border-radius: 3px"
        :src="HLlogo"
      />
    </b-button>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import { eventBus } from "../../main";
import HLlogo from "../../assets/img/HL-Logo.png";

export default {
  name: "HealthLinkButton",
  props: ["patient", "disabled"],
  data() {
    return {
      HLlogo,
    };
  },
  computed: {
    ...mapGetters({
      getBranchInfo: "moduleUser/getBranchInfo",
      isDoctor: "moduleUser/isDoctor",
    }),
    isDisplay() {
      return (
        this.getBranchInfo?.isFHIRAccessEnabled &&
        this.isDoctor &&
        this.patient.id &&
        this.patient.personalId
      );
    },
  },
  methods: {
    ...mapActions({
      grantToken: "moduleFhir/grantToken",
    }),
    async openHealthLinkWindow() {
      try {
        eventBus.$emit("appLoadingStatus", true);

        let { clinicUrl, branchUrl } = this.$route.params;
        let res = await this.grantToken({
          clinicUrl,
          branchUrl,
          patientId: this.patient.id,
        });
        window.open(res.url, "_blank");
      } catch (err) {
        console.error(err);
      } finally {
        eventBus.$emit("appLoadingStatus", false);
      }
    },
  },
};
</script>

<style>
</style>