<template>
  <b-modal
    id="ReminderModal"
    ref="ReminderModal"
    size="sm"
    centered
    hide-header
    hide-footer
    hide-backdrop
    @shown="stopPropagationModal"
    @hide="save($event)"
  >
    <b-form-group
      class="mb-2"
      label="โน๊ตนัดหมาย:"
      description="บันทึกรายละเอียดเช่น งานแลปยังไม่เสร็จ, อุปกรณ์มาเวลา 15.00 น. เป็นต้น"
    >
      <b-form-textarea
        class="mb-0"
        v-model="note"
        trim
        placeholder="รายละเอียดเพิ่มเติม..."
        rows="3"
      ></b-form-textarea>
    </b-form-group>
    <b-row>
      <b-col class="pl-3 pr-1">
        <b-button
          v-for="reminder in reminderListIncompleted"
          :key="reminder.val"
          class="border-0 mb-2"
          size="sm"
          block
          :style="{ backgroundColor: reminder.desc }"
          @click="selectReminder(reminder.val)"
        >{{ reminder.label }}</b-button>
      </b-col>
      <b-col class="pl-1 pr-3">
        <b-button
          v-for="reminder in reminderListCompleted"
          :key="reminder.val"
          class="border-0 mb-2"
          size="sm"
          block
          :style="{ backgroundColor: reminder.desc }"
          @click="selectReminder(reminder.val)"
        >{{ reminder.label }}</b-button>
      </b-col>
    </b-row>
    <Loading v-if="isLoading"></Loading>
    <Dialog ref="Dialog"></Dialog>
  </b-modal>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import Loading from "@/components/Loading";
import Dialog from "@/components/modal/Dialog";

export default {
  name: "ReminderModal",
  components: {
    Loading,
    Dialog
  },
  data() {
    return {
      isLoading: false,
      appointment: null,
      note: "",
      rmdId: null
    };
  },
  computed: {
    ...mapGetters({
      getBranchInfo: "moduleUser/getBranchInfo",
      appointmentBy: "moduleAppointment/getQueueAppointmentBy",
      getReminderList: "moduleMaster/getReminderList"
    }),
    reminderListIncompleted() {
      return this.getReminderList.filter(i => i.val <= 10);
    },
    reminderListCompleted() {
      return this.getReminderList.filter(i => i.val > 10);
    }
  },
  watch: {},

  methods: {
    show(appointment) {
      this.appointment = this.appointmentBy(appointment.id, "id");
      this.note = appointment.extendedProps.note;
      this.rmdId = appointment.extendedProps.rmdId;
      this.$bvModal.show("ReminderModal");
    },
    stopPropagationModal() {
      document
        .getElementById("ReminderModal___BV_modal_outer_")
        .addEventListener("click", e => e.stopPropagation());
    },
    selectReminder(rmdId) {
      this.rmdId = rmdId;
      this.$bvModal.hide("ReminderModal");
    },
    async save(e) {
      if (this.note != this.appointment.extendedProps.note) {
        await this.$refs.Dialog.showAlertConfirm(
          'มีการเปลี่ยนแปลง "โน๊ตนัดหมาย"',
          'คุณต้องการบันทึก "โน๊ตนัดหมาย"?'
        ).then(res => {
          if (res.isDismissed) {
            this.note = this.appointment.extendedProps.note;
          }
        });
        document
          .querySelector(".swal2-container")
          .addEventListener("click", e => e.stopPropagation());
      }
      if (
        this.note === this.appointment.extendedProps.note &&
        this.rmdId === this.appointment.extendedProps.rmdId
      )
        return;
      this.$emit("isLoading", true);
      await this.updateApt();
      this.$emit("hidePopover");
      this.$emit("isLoading", false);
    },
    async updateApt() {
      let eventData = {
        id: this.appointment.id,
        startDt: this.appointment.start,
        endDt: this.appointment.end,
        roomId: parseInt(this.appointment.resourceId),
        aptTreatItems: this.appointment.extendedProps.treatmentList,
        doctorUid: this.appointment.extendedProps.doctor,
        patientId: this.appointment.extendedProps.patient.id,
        note: this.note,
        rmdId: this.rmdId
      };

      await this.$store
        .dispatch("moduleAppointment/updateQueueAppointment", {
          event: eventData,
          clinicUrl: this.$route.params.clinicUrl,
          branchUrl: this.$route.params.branchUrl
        })
        .then(res => {
          // if (res.status == "200") {
          // }
        })
        .catch(err => {
          console.error(err);
        });
    },
    defaultValue() {
      this.appointment = null;
      this.note = "";
      this.rmdId = null;
    }
  }
};
</script>

<style scoped>
#ReminderModal___BV_modal_outer_ {
  z-index: 1060 !important;
}
</style>