<template>
  <b-row>
    <div
      class="col-12 col-lg-2 divider-lg-right divider-bottom divider-lg-bottom-0 mb-3 mb-lg-0"
    >
      <PatientProfile
        :patient="patient"
        button-name="ข้อมูลติดต่อ"
        :button-click="
          () => {
            editCustomer(patient);
          }
        "
      />
    </div>

    <div class="col-12 col-lg-10">
      <b-container fluid class="photo-gallery">
        <b-overlay :show="isFetching" rounded="sm" spinner-variant="primary">
          <b-card
            v-for="category in fileCategories"
            :key="category.value"
            body-class="p-2"
          >
            <b-card-title class="mb-1 text-small">
              {{ category.text }}
              <b-button
                class="ml-2"
                size="xs"
                variant="primary"
                @click="showUploadModal(category.value)"
                >+</b-button
              ></b-card-title
            >
            <b-card-text class="mx-2">
              <b-row>
                <b-col
                  v-for="(item, index) in filteredList(category.value)"
                  :key="item.id"
                  class="p-1"
                  cols="6"
                  sm="4"
                  md="3"
                  lg="2"
                  xl="2"
                  @click="
                    handleThumbnailClick(index, filteredList(category.value))
                  "
                >
                  <p class="small-text mb-0 text-muted mr-1">
                    วันที่ Upload: {{ formatDate(item.creationDt) }}
                  </p>
                  <div
                    v-if="item.isDeleting"
                    class="d-flex justify-content-center"
                  >
                    <b-spinner
                      class="mt-2"
                      variant="danger"
                      label="Deleting..."
                    ></b-spinner>
                  </div>
                  <div v-else class="img-wrap">
                    <div class="img-delete-button show-when-hovered">
                      <b-badge
                        v-if="item.fileType === 'application/pdf'"
                        class="mr-2"
                        variant="success"
                        href="#"
                        @click.stop="openSignTab(item)"
                        >Sign</b-badge
                      >
                      <b-badge
                        v-if="category.value === 'XRay'"
                        class="mr-2"
                        variant="info"
                        href="#"
                        @click.stop="openRadiiView(item)"
                        >RadiiView
                      </b-badge>
                      <b-badge
                        variant="danger"
                        href="#"
                        @click.stop="deleteImg(item)"
                        >&times;</b-badge
                      >
                    </div>
                    <div
                      v-if="item.fileType === 'application/pdf'"
                      class="text-center m-2"
                    >
                      <i class="far fa-file-pdf" style="font-size: 60px"></i>
                    </div>

                    <b-img
                      v-else
                      thumbnail
                      fluid
                      :src="item.src"
                      :alt="item.title"
                    ></b-img>
                    <p
                      class="mb-0 text-center description"
                      v-b-tooltip.hover.right
                      :title="item.remark"
                    >
                      {{ truncateText(item.remark) }}
                    </p>
                  </div>
                </b-col>
              </b-row>
            </b-card-text>
          </b-card>
        </b-overlay>
      </b-container>
    </div>
    <Lightbox :index="lightBoxIndex" :items="lightBoxList" @close="resetList" />
    <UploadFilesModal
      :patientId="patient.id"
      ref="uploadFiles"
      @uploadedFiles="pollForUpdates"
    />
    <Loading v-if="isLoading"></Loading>
    <Dialog ref="Dialog"></Dialog>
  </b-row>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import { eventBus } from "@/main";
import VueLightbox from "vue-lightbox-lite";
import UploadFilesModal from "@/components/modal/UploadFiles";
import Dialog from "@/components/modal/Dialog";
import Loading from "@/components/Loading";
import { MAX_POLL_ATTEMPS, POLLING_INTERVAL } from "@/constants";
import "vue-lightbox-lite/dist/index.css";
import truncateText from "@/utils/truncateText";
import formatDate from "@/utils/formatDate";

import moment from "moment";

export default {
  name: "PhotoGallery",
  components: { Lightbox: VueLightbox, UploadFilesModal, Dialog, Loading },
  props: ["patient"],
  data() {
    return {
      fileList: [],
      formattedList: [],
      fileCategories: [
        { text: "Intra-Oral", value: "IntraOralPhoto" },
        { text: "Extra-Oral", value: "ExtraOralPhoto" },
        { text: "X-Ray", value: "XRay" },
        { text: "OPD Card", value: "OpdCard" },
        // { text: "Invoice", value: "Invoice" },
        // { text: "Document", value: "Document" },
        // { text: "Model3D", value: "Model3d" },
        // { text: "Video", value: "Video" },
      ],
      lightBoxIndex: null,
      lightBoxList: [],

      isLoading: false,
      isFetching: false,
      limit: 100,

      highestId: 0,
      pollInterval: null,
      continuePolling: true, // Flag to indicate whether to continue polling
      fetchAttempts: 0, // Counter to keep track of fetch attempts
      uploadedFiles: null,
      spinnerTaostShow: true,

      truncateText,
      formatDate,
    };
  },
  computed: {
    ...mapGetters({
      getFileList: "moduleMediaFile/getFileList",
    }),
  },
  watch: {},
  created() {},
  methods: {
    ...mapActions({
      fecthMediaFiles: "moduleMediaFile/fecthMediaFiles",
      deleteMediaFile: "moduleMediaFile/deleteMediaFile",
      fetchRadiiViewUrl: "moduleMediaFile/fetchRadiiViewUrl",
    }),
    editCustomer(patient) {
      this.$emit(
        "openCustomerModal",
        Object.assign({}, patient),
        this.appointment
      );
    },
    handleThumbnailClick(key, list) {
      this.lightBoxIndex = key;
      this.lightBoxList = list;
    },
    resetList() {
      this.lightBoxIndex = null;
      this.lightBoxList = [];
    },
    showUploadModal(category) {
      this.$refs["uploadFiles"].show({ category });
    },
    async openRadiiView({ src }) {
      try {
        const { data } = await this.fetchRadiiViewUrl({ imageUrl: src });
        window.open(data.viewer, "_blank");
      } catch (err) {
        console.error(err);
      }
    },

    async deleteImg(item) {
      try {
        const { isConfirmed } = await this.$refs.Dialog.showAlertConfirm(
          "ลบไฟล์นี้ ?",
          `คุณต้องการลบไฟล์นี้`
        );
        if (isConfirmed) {
          this.$set(item, "isDeleting", true);
          const res = await this.deleteMediaFile({
            clinicUrl: this.$route.params.clinicUrl,
            branchUrl: this.$route.params.branchUrl,
            id: item.id,
          });
          if (res.status === 200) {
            this.fileList = this.fileList.filter(i => {
              return item.id !== i.id;
            });
            this.formattedList = this.formatList();
          }
        }
      } catch (err) {
        console.error(err);
      } finally {
        this.$set(item, "isDeleting", false);
      }
    },
    formatList() {
      return this.fileList.map(i => {
        return {
          id: i.id,
          src: i.mediaURL,
          // thumb: i.thumbnailURL,
          title: i.category,
          category: i.category,
          mediaType: i.fileType === "application/pdf" ? "iframe" : null,
        };
      });
    },
    async fetchFiles() {
      this.fecthMediaFiles({
        clinicUrl: this.$route.params.clinicUrl,
        branchUrl: this.$route.params.branchUrl,
        params: { patientId: this.patient.id, limit: this.limit },
      })
        .then(response => {
          this.fileList = response;

          const newFiles = this.fileList.filter(
            file => file.id > this.highestId
          );
          if (
            newFiles.length > 0 &&
            (this.uploadedFiles === null ||
              newFiles.length === this.uploadedFiles?.length)
          ) {
            this.continuePolling = false; // Stop polling
          } else {
            this.fetchAttempts++; // Increment fetch attempts
            // Check if reached maximum fetch attempts
            if (this.fetchAttempts >= MAX_POLL_ATTEMPS) {
              this.continuePolling = false; // Stop polling
            }
          }
        })
        .catch(error => {
          console.error("Error fetching files:", error);
        })
        .finally(() => {
          if (!this.continuePolling) {
            eventBus.$emit("hideSpinnerToast");

            clearInterval(this.pollInterval); // Stop polling
            // Update the highest ID seen so far
            if (this.fileList.length > 0) {
              this.highestId = Math.max(...this.fileList.map(file => file.id));
            }
            this.setDefaultPollingData();
          }
        });
    },
    pollForUpdates(e) {
      if (this.spinnerTaostShow) eventBus.$emit("showSpinnerToast");
      this.uploadedFiles = e;
      this.highestId = Math.max(...this.getFileList.map(file => file.id));
      this.pollInterval = setInterval(() => {
        this.fetchFiles();
      }, POLLING_INTERVAL);
    },

    filteredList(category) {
      return this.getFileList.filter(i => i.category === category);
    },
    setDefaultPollingData() {
      this.highestId = 0;
      this.pollInterval = null;
      this.continuePolling = true; // Flag to indicate whether to continue polling
      this.fetchAttempts = 0; // Counter to keep track of fetch attempts
      this.spinnerTaostShow = true;
    },
  },
  async mounted() {
    eventBus.$on("pollForUpdates", this.pollForUpdates);
  },
  beforeDestroy() {
    eventBus.$off("pollForUpdates", this.pollForUpdates);
  },
};
</script>

<style>
.img-wrap {
  position: relative;
}
.img-wrap .img-delete-button {
  position: absolute;
  font-size: 1.2em;
  top: -5px;
  right: -3px;
  z-index: 100;
  cursor: pointer;
  display: none;
}
.img-wrap:hover .img-delete-button {
  display: block;
}
.spinner-overlay {
  position: absolute;
  top: 50%;
  left: 50%;
  opacity: 0.5;
  transform: translate(-50%, -50%);
}
</style>
