<template>
  <div class="content-wrapper view-doctor-treating-queue pt-3">
    <div class="content">
      <div class="row ml-0" style="max-width: 100%">
        <div class="col">
          <!-- month calendar -->
          <b-card no-body>
            <b-collapse id="doctorCalendar" v-model="isCalendarOpen">
              <div class="row">
                <div class="col-12">
                  <b-calendar
                    v-model="dateDefault"
                    class="col border rounded p-1"
                    selected-variant="primary"
                    today-variant="info"
                    locale="th-TH"
                    :hide-header="true"
                    block
                  ></b-calendar>
                </div>
              </div>
            </b-collapse>
          </b-card>
          <!-- selecting doctorƒ -->
          <b-card no-body class="p-2">
            <b-form-group
              class="mb-1"
              label="เลือกแพทย์:"
              label-for="doctor-txq-select"
              invalid-feedback="กรุณาเลือกแพทย์"
              :state="isDoctorSelected"
              ><b-form-select
                id="doctor-txq-select"
                v-model="doctorNameSelected"
                :options="doctorNameList"
                :select-size="4"
                :state="isDoctorSelected"
              ></b-form-select
            ></b-form-group>
          </b-card>

          <div class="row">
            <div class="col-12">
              <!-- table queue treatment -->
              <b-card no-body>
                <b-table
                  class="mb-0 tx-queue-table"
                  hover
                  small
                  sort-icon-left
                  sort-by="checkinDt"
                  sticky-header="100%"
                  :items="doctorSelectedCheckin"
                  :fields="tableHeaders"
                  head-variant="light"
                >
                  <template v-slot:cell(index)="row"
                    >{{ row.index + 1 }}.</template
                  >
                  <template v-slot:cell(start)="row">
                    {{ checkTimeAppointment(row.item) }}
                  </template>
                  <template v-slot:cell(checkinDt)="row">
                    {{ formatTime(row.item.checkinDt) }}
                  </template>
                  <template v-slot:cell(checkinStatus)="row">
                    <span
                      :class="{
                        onTime: row.value == 1,
                        early: row.value == 2,
                        late: row.value == 3,
                        walkIn: row.value == 4,
                      }"
                      >{{ row.item.checkinStatusName }}</span
                    >
                  </template>
                  <template v-slot:cell(note)="row">
                    {{ row.item.checkinNote }}
                  </template>
                </b-table>
                <b-button
                  v-b-toggle.txQueueColorsDetail
                  size="sm"
                  class="m-3"
                  variant="outline-info"
                >
                  <i class="fas fa-palette"></i> ความหมายของสีในคิว
                </b-button>
                <b-collapse id="txQueueColorsDetail" class>
                  <b-col>
                    <b-table-simple hover small caption-top>
                      <b-tr variant="purple ">
                        <b-td class="text-center border-white rounded">
                          อยู่ในคิวรอตรวจนานกว่า
                          <strong>45 นาที</strong>
                        </b-td>
                      </b-tr>
                      <b-tr variant="red">
                        <b-td class="text-center border-white rounded">
                          อยู่ในคิวรอตรวจ
                          <strong>30-45 นาที</strong>
                        </b-td>
                      </b-tr>
                      <b-tr variant="yellow">
                        <b-td class="text-center border-white rounded">
                          อยู่ในคิวรอตรวจ
                          <strong>15-30 นาที</strong>
                        </b-td>
                      </b-tr>
                      <b-tr variant="green">
                        <b-td class="text-center border-white rounded">
                          อยู่ในคิวรอตรวจน้อยกว่า
                          <strong>15 นาที</strong>
                        </b-td>
                      </b-tr>
                    </b-table-simple>
                  </b-col>
                </b-collapse>
              </b-card>
            </div>
          </div>
        </div>
        <div class="col">
          <div class="row">
            <div class="col-12">
              <b-card body-class="p-2">
                <fullCalendar
                  ref="calendar"
                  defaultView="resourceTimeGridDay"
                  :themeSystem="themeSystem"
                  height="auto"
                  :defaultDate="dateDefault"
                  :header="header"
                  :titleFormat="titleFormat"
                  :minTime="openingTime"
                  :maxTime="closingTime"
                  :slotDuration="slotDuration"
                  :plugins="calendarPlugins"
                  timeZone="Asia/Bangkok"
                  :locale="locales"
                  :slotLabelFormat="slotLabelFormat"
                  :resources="getDoctorRooms"
                  :events="doctorSelectedApt"
                  :droppable="false"
                  :editable="false"
                  :selectable="false"
                  :eventDurationEditable="false"
                  :nowIndicator="true"
                  :schedulerLicenseKey="schedulerLicenseKey"
                  :customButtons="customButtons"
                  :scrollTime="scrollTime"
                  :allDaySlot="false"
                  :handleWindowResize="true"
                  @windowResize="fcWindowResize"
                  @eventClick="fcAppointmentClick"
                  :eventRender="renderCalendarEvents"
                  :viewSkeletonRender="onViewSkeletonRender"
                ></fullCalendar>
              </b-card>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- modal dialog -->
    <CustomerModal
      ref="customerModal"
      @openProfileCustomerModal="openProfileCustomerModal"
    ></CustomerModal>
    <searchCustomerModal
      ref="searchCustomerModal"
      @openProfileCustomerModal="openProfileCustomerModal"
      @openCustomerModal="openCustomerModal"
    ></searchCustomerModal>
    <ProfileCustomerModal
      ref="profileCustomerModal"
      @openCustomerModal="openCustomerModal"
    ></ProfileCustomerModal>
    <StatusModal
      ref="statusModal"
      @hidePopover="hidePopOver()"
      @isLoading="isLoading = $event"
    />
    <ReminderModal
      ref="reminderModal"
      @hidePopover="hidePopOver()"
      @isLoading="isLoading = $event"
    />

    <ColorSidebar ref="colorSidebar" />

    <Dialog ref="Dialog"></Dialog>

    <Notification></Notification>

    <div id="appointmentPopover" class="d-none">
      <div v-if="lastFcAppointmentSelected">
        <div v-if="isPatientOne" class="row">
          <div class="col d-flex">
            <div class="align-self-stretch small-text">
              <p class="mb-1 mt-1">
                {{ lastFcAppointmentSelected.extendedProps.treatmentPeriod }}
              </p>
              <hr class="my-1" />

              <h6 class="narrow-spacing mb-0">
                <strong>
                  คนไข้ใหม่
                  <br />
                  ไม่มีประวัติ
                </strong>
              </h6>
              <hr class="my-1" />
              <p class="mb-1 narrow-spacing">
                แพทย์:
                {{ lastFcAppointmentSelected.extendedProps.doctorName }}
              </p>
              <p class="mb-1 narrow-spacing">
                <strong
                  v-html="
                    lastFcAppointmentSelected.extendedProps.treatmentTitle
                  "
                ></strong>
              </p>
              <p
                class="mb-1 narrow-spacing"
                v-if="lastFcAppointmentSelected.extendedProps.note"
              >
                โน๊ต: {{ lastFcAppointmentSelected.extendedProps.note }}
              </p>
              <b-button
                v-if="lastFcAppointmentSelected.extendedProps.rmdId"
                id="btnReminder"
                ref="btnReminder"
                class="border-0 mb-1"
                block
                :style="{
                  'background-color': findReminderObj(
                    lastFcAppointmentSelected.extendedProps.rmdId
                  ).desc,
                }"
                size="sm"
                disabled
              >
                {{
                  findReminderObj(lastFcAppointmentSelected.extendedProps.rmdId)
                    .label
                }}
              </b-button>
              <b-button
                id="btnStatus"
                ref="btnStatus"
                class="border-0"
                block
                :style="{
                  'background-color':
                    lastFcAppointmentSelected.extendedProps.statusColor,
                }"
                size="sm"
                disabled
              >
                {{
                  findStatusName(
                    lastFcAppointmentSelected.extendedProps.statusId
                  ).label
                }}
              </b-button>
            </div>
          </div>
        </div>
        <div v-else-if="isPatientNull" class="row">
          <div class="col d-flex">
            <div class="align-self-stretch small-text">
              <p class="mb-1 mt-1">
                {{ lastFcAppointmentSelected.extendedProps.treatmentPeriod }}
              </p>
              <hr class="my-1" />
              <p
                class="mb-1 narrow-spacing"
                v-if="lastFcAppointmentSelected.extendedProps.note"
              >
                <strong>{{
                  lastFcAppointmentSelected.extendedProps.note
                }}</strong>
              </p>
              <p
                class="mb-1 narrow-spacing"
                v-if="lastFcAppointmentSelected.extendedProps.doctor"
              >
                แพทย์: {{ lastFcAppointmentSelected.extendedProps.doctorName }}
              </p>
            </div>
          </div>
        </div>
        <div v-else class="row">
          <div class="col-7 pl-3 pr-0 d-flex">
            <div class="align-self-stretch small-text">
              <p class="mb-1">
                {{ lastFcAppointmentSelected.extendedProps.treatmentPeriod }}
              </p>
              <hr class="my-1" />
              <p class="mb-0">
                DN: {{ lastFcAppointmentSelected.extendedProps.dn }}
              </p>
              <p
                v-if="lastFcAppointmentSelected.extendedProps.existDn"
                class="mb-1 description text-muted"
              >
                DN(เดิม):
                {{ lastFcAppointmentSelected.extendedProps.existDn }}
              </p>
              <p
                v-if="
                  lastFcAppointmentSelected.extendedProps.patient.titleNameTh
                "
                class="mb-0"
              >
                {{
                  lastFcAppointmentSelected.extendedProps.patient.titleNameTh
                }}
              </p>
              <h6 class="narrow-spacing mb-0">
                <strong>
                  {{
                    lastFcAppointmentSelected.extendedProps.patient.firstNameTh
                  }}
                  <br />
                  {{
                    lastFcAppointmentSelected.extendedProps.patient.lastNameTh
                  }}
                </strong>
              </h6>
              <p
                class="norrow-spacing mb-0"
                v-if="lastFcAppointmentSelected.extendedProps.patient.dob"
              >
                อายุ:
                {{
                  calAge(lastFcAppointmentSelected.extendedProps.patient.dob)
                }}
                ปี
              </p>
              <hr class="my-1" />

              <p class="mb-1 narrow-spacing">
                แพทย์:
                {{ lastFcAppointmentSelected.extendedProps.doctorName }}
              </p>
              <p class="mb-1 narrow-spacing">
                <strong
                  v-html="
                    lastFcAppointmentSelected.extendedProps.treatmentTitle
                  "
                ></strong>
              </p>
              <p
                v-if="lastFcAppointmentSelected.extendedProps.note"
                class="mb-1 narrow-spacing"
              >
                <strong>
                  โน๊ต:
                  {{ lastFcAppointmentSelected.extendedProps.note }}
                </strong>
              </p>
              <b-button
                v-if="lastFcAppointmentSelected.extendedProps.rmdId"
                id="btnReminder"
                ref="btnReminder"
                class="border-0 mb-1"
                block
                disabled
                :style="{
                  'background-color': findReminderObj(
                    lastFcAppointmentSelected.extendedProps.rmdId
                  ).desc,
                }"
                size="sm"
              >
                {{
                  findReminderObj(lastFcAppointmentSelected.extendedProps.rmdId)
                    .label
                }}
              </b-button>
              <b-button
                id="btnStatus"
                ref="btnStatus"
                class="border-0"
                block
                disabled
                :style="{
                  'background-color':
                    lastFcAppointmentSelected.extendedProps.statusColor,
                }"
                size="sm"
              >
                {{
                  findStatusName(
                    lastFcAppointmentSelected.extendedProps.statusId
                  ).label
                }}
              </b-button>
            </div>
          </div>
          <div class="col-5 d-flex justify-content-end">
            <b-button-group vertical class="align-self-stretch">
              <button id="btnPatientDetail" class="btn btn-info">
                <i class="fa fa-info-circle" aria-hidden="true"></i>
                <br />ประวัติ <br />คนไข้
              </button>
            </b-button-group>
          </div>
        </div>
      </div>
    </div>
    <Loading v-if="isLoading"></Loading>
  </div>
</template>

<script>
import Vue from "vue";
import { mapGetters, mapActions } from "vuex";
import { eventBus } from "../main";

import CustomerModal from "@/components/modal/Customer";
import SearchCustomerModal from "@/components/modal/SearchCustomer";
import ProfileCustomerModal from "@/components/modal/ProfileCustomer";
import StatusModal from "@/components/modal/Status";
import ReminderModal from "@/components/modal/Reminder";

import ColorSidebar from "@/components/menubar/ColorSidebar";

import Dialog from "@/components/modal/Dialog";
import Notification from "@/components/Notification";
import Loading from "@/components/Loading";

import moment from "moment";
import fullCalendar from "@fullcalendar/vue";
import interaction, { Draggable } from "@fullcalendar/interaction";
import fcBootstrap from "@fullcalendar/bootstrap";
import resourceTimeGridPlugin from "@fullcalendar/resource-timegrid";
import locales from "@fullcalendar/core/locales/th";
import momentTimezonePlugin from "@fullcalendar/moment-timezone";

import bootstrap from "bootstrap";
import $ from "jquery";
import "@/theme/DoctorsTreatingQueue.scss";

export default {
  name: "DoctorsTreatingQueue",
  components: {
    CustomerModal,
    SearchCustomerModal,
    ProfileCustomerModal,
    StatusModal,
    ReminderModal,

    fullCalendar,
    Dialog,
    Notification,
    Loading,
    ColorSidebar,
  },
  data() {
    return {
      isLoading: false,
      appointmentTempData: null,
      themeSystem: "bootstrap",
      displayPage: false,
      dateDefault: moment().format("YYYY-MM-DD"),
      dateShower: false,
      schedulerLicenseKey: "0650622495-fcs-1679632464",
      locales: locales,
      slotLabelFormat: {
        hour: "2-digit",
        minute: "2-digit",
        omitZeroMinute: false,
        meridiem: "short",
      },
      header: {
        left: "prevDay,todayButton,nextDay toggleSidebar",
        center: "title",
        right: "",
      },
      titleFormat: {
        month: "long",
        year: "2-digit",
        day: "numeric",
        weekday: "short",
      },
      calendarPlugins: [
        interaction,
        resourceTimeGridPlugin,
        fcBootstrap,
        momentTimezonePlugin,
      ],
      windowHeight: window.innerHeight,
      fetchEventTimeout: 0,
      tableHeaders: [
        {
          key: "index",
          label: "",
          class: "text-center",
        },
        {
          label: "คิวรอตรวจ",
          sortable: false,
          key: "title",
        },
        {
          label: "ข้อความ",
          sortable: false,
          key: "note",
        },
        {
          label: "สถานะ",
          sortable: true,
          key: "checkinStatus",
        },
        {
          label: "เวลานัด",
          sortable: true,
          key: "start",
        },
        {
          label: "เวลามาถึง",
          sortable: true,
          key: "checkinDt",
        },
      ],
      lastFcAppointmentSelected: null,
      isTodayApt: true,
      isCheckIn: true,
      popEl: null,
      isCalendarOpen: true,

      doctorNameSelected: "",
      notifyAll: true,
    };
  },
  created() {
    this.init();
  },
  watch: {
    dateDefault: function (date) {
      this.getAllDoctorsData(date);
      this.setStateAllDoctorsDate(date);
      this.getCalendarApi().gotoDate(date);
    },
    lastFcAppointmentSelected() {
      this.isTodayApt = moment().isSame(
        this.lastFcAppointmentSelected.start,
        "day"
      );
      this.isCheckIn =
        this.lastFcAppointmentSelected.extendedProps.statusId === 5;
    },
  },
  computed: {
    ...mapGetters({
      roomList: "moduleUser/getRoomList",
      branchInfo: "moduleUser/getBranchInfo",
      doctorAppointmentList: "moduleAppointment/getDoctorAppointmentList",
      doctorCheckInList: "moduleAppointment/getDoctorCheckInList",
      getAppointmentStatusList: "moduleMaster/getAppointmentStatusList",
      getReminderList: "moduleMaster/getReminderList",

      getAllDoctorsAppointment: "moduleAppointment/getAllDoctorsAppointment",
      getAllCheckInList: "moduleAppointment/getAllCheckInList",
      appointmentBy: "moduleAppointment/getDoctorsAppointmentBy",
    }),

    fullCalendarHeight() {
      return this.windowHeight - 80;
    },
    customButtons() {
      return {
        prevDay: {
          text: "prev",
          bootstrapFontAwesome: "fa-chevron-left",
          click: () => {
            this.dateDefault = moment(this.dateDefault)
              .add(-1, "days")
              .format("YYYY-MM-DD");
          },
        },
        todayButton: {
          text: "วันนี้",
          click: () => {
            this.dateDefault = moment().format("YYYY-MM-DD");
          },
        },
        nextDay: {
          text: "next",
          bootstrapFontAwesome: "fa-chevron-right",
          click: () => {
            this.dateDefault = moment(this.dateDefault)
              .add(1, "days")
              .format("YYYY-MM-DD");
          },
        },
        collapseCalendar: {
          text: "Collapse",
          bootstrapFontAwesome: "angle-double-up",
          click: () => {
            this.isCalendarOpen = !this.isCalendarOpen;
          },
        },
        toggleSidebar: {
          text: "toggle",
          bootstrapFontAwesome: "fa-sliders-h",
          click: () => {
            this.$refs.colorSidebar.toggle({
              slotDuration: this.slotDuration,
              notifyAll: this.notifyAll,
            });
          },
        },
      };
    },
    scrollTime() {
      return moment().format("HH:mm:ss");
    },
    getDoctorRooms() {
      const doctorSelectedApt = this.doctorSelectedApt;
      const roomList = this.roomList;
      let rooms = roomList.filter((room) => {
        const found = doctorSelectedApt.findIndex((event) => {
          return event.resourceId == room.id;
        });
        return found != -1;
      });

      if (rooms.length == 0) {
        return [
          {
            id: 0,
            title: "ไม่พบนัดหมาย",
          },
        ];
      } else {
        return rooms;
      }
    },
    isQueuePayment() {
      if (this.lastFcAppointmentSelected) {
        return this.lastFcAppointmentSelected.extendedProps.statusId === 6
          ? true
          : false;
      } else {
        return false;
      }
    },
    isNoQueue() {
      return this.doctorCheckInList.length === 0;
    },
    isToday() {
      return moment().isSame(this.dateDefault, "day");
    },
    tableCheckinList() {
      if (this.doctorCheckInList.length === 0) {
        return [];
      } else {
        return this.doctorCheckInList.map((item) => {
          return {
            id: item.id,
            title:
              item.extendedProps.patientName +
              "\n" +
              item.extendedProps.treatmentTitle,
            nameTh: item.extendedProps.patientName,
            dn: item.extendedProps.dn,
            txTitle: item.extendedProps.treatmentTitle,
            start: item.start,
            checkinDt: item.extendedProps.checkin.checkinDt,
            checkinStatus: item.extendedProps.checkin.checkinStatus,
            checkinStatusName: item.extendedProps.checkin.checkinStatusName,
            checkinNote: item.extendedProps.checkin.checkinNote,
            _rowVariant: "",
          };
        });
      }
    },
    doctorNameList() {
      let list = this.getAllDoctorsAppointment.map(
        (i) => i.extendedProps.doctorName
      );
      list = [...new Set(list)];
      return list;
    },
    doctorSelectedApt() {
      return this.getAllDoctorsAppointment.filter(
        (apt) => apt.extendedProps.doctorName === this.doctorNameSelected
      );
    },
    doctorSelectedCheckin() {
      let doctorList = this.getAllCheckInList.filter(
        (i) =>
          i.extendedProps.doctorName == this.doctorNameSelected &&
          i.extendedProps.statusId === 5
      );
      if (doctorList[0]) {
        return doctorList.map((item) => {
          return {
            id: item.id,
            title: item.title,
            nameTh: item.extendedProps.patientName,
            dn: item.extendedProps.dn,
            txTitle: item.extendedProps.treatmentTitle,
            start: item.start,
            checkinDt: item.extendedProps.checkin.checkinDt,
            checkinStatus: item.extendedProps.checkin.checkinStatus,
            checkinStatusName: item.extendedProps.checkin.checkinStatusName,
            checkinNote: item.extendedProps.checkin.checkinNote,
            _rowVariant: "",
          };
        });
      } else {
        return [];
      }
    },
    isDoctorSelected() {
      return this.doctorNameSelected.length > 0;
    },
    slotDuration() {
      return this.branchInfo?.confApptDuration
        ? this.branchInfo.confApptDuration
        : "00:15";
    },
    openingTime() {
      return this.branchInfo?.confOpeningTime
        ? this.branchInfo.confOpeningTime
        : "09:00";
    },
    closingTime() {
      return this.branchInfo?.confClosingTime
        ? this.branchInfo.confClosingTime
        : "21:00";
    },
    isPatientOne() {
      return this.lastFcAppointmentSelected?.extendedProps?.patient?.id == 1;
    },
    isPatientNull() {
      return !this.lastFcAppointmentSelected?.extendedProps?.patient?.id;
    },
  },
  methods: {
    ...mapActions({
      fetchDoctorAppointmentList:
        "moduleAppointment/fetchDoctorAppointmentList",
      cancelWaitingForPaymentAppointment:
        "moduleAppointment/cancelWaitingForPaymentAppointment",

      setStateAllDoctorsDate: "moduleAppointment/setStateAllDoctorsDate",
      fetchAllDoctorsAppointment:
        "moduleAppointment/fetchAllDoctorsAppointment",
      fetchAllCheckInList: "moduleAppointment/fetchAllCheckInList",
    }),
    init() {
      this.isLoading = true;

      this.getAllDoctorsData();
      this.setStateAllDoctorsDate(this.dateDefault);

      eventBus.$on("notifyTxPt", this.notifyTxPt);
    },
    offJQuery() {
      $(document).off("click", "#btnPatientDetail");
      $(document).off("click", "#btnStatus");
      $(document).off("click", "#btnReminder");
      $(document).off("click.hidePopover");
    },
    onJQuery() {
      const self = this;

      $(document).on("click", "#btnPatientDetail", () => {
        const patient = this.lastFcAppointmentSelected.extendedProps.patient;
        const appointment = this.appointmentBy(
          this.lastFcAppointmentSelected.id,
          "id"
        );
        self.openProfileCustomerModal(appointment, patient, true);
        self.hidePopOver();
      });

      //close popover when click outside
      $(document).on("click.hidePopover", (e) => {
        if (
          self.popEl &&
          !$(self.popEl).is(e.target) &&
          $(self.popEl).has(e.target).length === 0 &&
          $(".popover").has(e.target).length === 0
        ) {
          self.hidePopOver();
        }
      });
      $(document).on("click", "#btnStatus", () => {
        self.openStatusModal(this.lastFcAppointmentSelected);
      });
      $(document).on("click", "#btnReminder", () => {
        self.openReminderModal(this.lastFcAppointmentSelected);
      });
    },

    async getAllDoctorsData(date, silent = false) {
      try {
        if (!silent) this.isLoading = true;
        if (!date) date = this.dateDefault;
        let res = await this.fetchAllDoctorsAppointment({
          clinicUrl: this.$route.params.clinicUrl,
          branchUrl: this.$route.params.branchUrl,
          date: date,
        });
      } catch (error) {
        console.error(error);
      } finally {
        this.isLoading = false;
      }
    },
    openCustomerModal(patient, appointment, readOnly) {
      this.$refs.customerModal.show(patient, appointment, readOnly);
    },
    openSearchCustomerModalNoSelect() {
      this.$refs.searchCustomerModal.show();
    },
    openProfileCustomerModal(appointment, patient, hideFooter) {
      this.$refs.profileCustomerModal.show(appointment, patient, hideFooter);
    },
    openStatusModal(appointment) {
      this.$refs.statusModal.show(appointment);
    },
    openReminderModal(appointment) {
      this.$refs.reminderModal.show(appointment);
    },
    renderCalendarEvents(info) {
      function shadeColor(color, percent) {
        var R = parseInt(color.substring(1, 3), 16);
        var G = parseInt(color.substring(3, 5), 16);
        var B = parseInt(color.substring(5, 7), 16);

        R = parseInt((R * (100 + percent)) / 100);
        G = parseInt((G * (100 + percent)) / 100);
        B = parseInt((B * (100 + percent)) / 100);

        R = R < 255 ? R : 255;
        G = G < 255 ? G : 255;
        B = B < 255 ? B : 255;

        R = Math.round(R / 10) * 10;
        G = Math.round(G / 10) * 10;
        B = Math.round(B / 10) * 10;

        var RR =
          R.toString(16).length == 1 ? "0" + R.toString(16) : R.toString(16);
        var GG =
          G.toString(16).length == 1 ? "0" + G.toString(16) : G.toString(16);
        var BB =
          B.toString(16).length == 1 ? "0" + B.toString(16) : B.toString(16);

        return "#" + RR + GG + BB;
      }

      let eventProps = info.event.extendedProps;
      const fcTime = info.el.getElementsByClassName("fc-time")[0];

      if (eventProps.patient?.id) {
        fcTime.classList.add("sideStatus");

        if (eventProps.statusId === 7) {
          info.el.style.backgroundColor = "#e0e0e0";
        } else if (eventProps.patient.id === 1) {
          info.el.classList.add("fc-unnamed-patient");
          info.el.style.backgroundColor = shadeColor(
            eventProps.doctorColor,
            -5
          );
        } else {
          info.el.style.backgroundColor = eventProps.doctorColor;
        }
        info.el.style.borderColor = eventProps.doctorColorBorder;
        fcTime.style.backgroundColor = eventProps.statusColor;
      } else {
        info.el.classList.add("fc-note-event");
        info.el.style.backgroundColor = "#5a6268";
        fcTime.style.display = "none";
      }

      if (eventProps.rmdId) {
        let rmdObj = this.findReminderObj(eventProps.rmdId);
        $(info.el.querySelector(".fc-title")).prepend(
          `<span class="mr-1 px-1 rounded" style="color: white; background-color: ${rmdObj.desc}">${rmdObj.code}</span>`
        );
      }
    },
    onViewSkeletonRender() {
      $("#calendar .fc-view-container").css({ "overflow-x": "auto" });
      $("#calendar .table-bordered").css(
        "min-width",
        $(".fc-resource-cell").length * 110
      );
    },
    fcAppointmentClick(info) {
      this.lastFcAppointmentSelected = Object.assign(info.event);
      this.popEl = info.el;
      this.hidePopOver();
      Vue.nextTick().then(function () {
        $(info.el)
          .popover({
            content: () => $("#appointmentPopover").html(),
            placement: "left",
            boundary: "scrollParent",
            boundaryPadding: 5,
            delay: { show: 500, hide: 100 },
            offset: 0,
            triggers: "",
            html: true,
            container: "body",
            sanitize: false,
            template:
              '<div class="popover" role="tooltip"><div class="arrow"></div><h3 class="popover-header"></h3><div class="popover-body p-1"></div></div>',
          })
          .popover("show");
      });
    },
    fcWindowResize(arg) {
      this.windowHeight = window.innerHeight;
    },
    hidePopOver() {
      $(".popover").not(this).popover("hide");
    },
    getCalendarApi() {
      return this.$refs.calendar.getApi();
    },
    checkTimeAppointment(data) {
      return data.checkinStatus != "04" ? this.formatTime(data.start) : "-";
    },
    formatTime(date) {
      return moment(date).format("HH:mm");
    },
    setToday() {
      this.dateDefault = moment().format("YYYY-MM-DD");
    },
    showDialogToast(variant, textDetail, noAutoHide) {
      this.$refs.Dialog.showToast(variant, textDetail, noAutoHide);
    },
    setBgColor() {
      this.doctorSelectedCheckin.forEach((item) => {
        let diff = moment().diff(item.checkinDt, "minutes");
        if (diff <= 8) {
          item._rowVariant = "green";
        } else if (diff <= 15) {
          item._rowVariant = "lightGreen";
        } else if (diff <= 30) {
          item._rowVariant = "yellow";
        } else if (diff <= 40) {
          item._rowVariant = "lightRed";
        } else if (diff <= 45) {
          item._rowVariant = "red";
        } else {
          item._rowVariant = "purple";
        }
      });
    },
    findStatusName(statusId) {
      return this.getAppointmentStatusList.find(function (item) {
        return item["code"] == statusId;
      });
    },
    findReminderObj(rmdId) {
      return this.getReminderList.find(function (item) {
        return item["val"] == rmdId;
      });
    },
    notifyTxPt(e) {
      this.blinkMe(e.doctor.id.userId);
      if (this.notifyAll) {
        this.showDialogToast(
          "warning",
          `คนไข้ชื่อ ${e.patient.firstNameTh + " " + e.patient.lastNameTh} 
          รอตรวจกับ แพทย์${e.doctor.user.fullName}`
        );
        eventBus.$emit("playAudio", "waitingForTreatment");
        return;
      }
      if (e.doctorUid == this.doctorId) {
        this.showDialogToast(
          "warning",
          `มีคนไข้ชื่อ ${
            e.patient.firstNameTh + " " + e.patient.lastNameTh
          }รอตรวจ`
        );
        eventBus.$emit("playAudio", "waitingForTreatment");
        return;
      }
    },
    blinkMe(id) {
      let index = this.getDoctorList.findIndex((dr) => dr.code == id);
      if (index == -1) return;
      let item = this.getDoctorList[index];
      item.blink = true;
      this.$set(this.getDoctorList, index, item);

      setTimeout(() => {
        item.blink = false;
        this.$set(this.getDoctorList, index, item);
      }, 6000);
    },
    calAge(dateString) {
      if (!dateString) return;
      const date = moment(dateString, "YYYY-MM-DD");
      return moment().diff(date, "years");
    },
  },
  mounted() {
    this.onJQuery();
    eventBus.$on("openSearchCustomerModal", () => {
      this.$refs.searchCustomerModal.show();
    });
    eventBus.$on("slotDurationSetting", (e) => {
      this.slotDuration = e;
    });
    eventBus.$on("notifyAllSetting", (e) => {
      this.notifyAll = e;
    });
    eventBus.$on("fetch-queue-data", async () => {
      await this.$nextTick();
      await this.$nextTick();
      this.getAllDoctorsData(this.dateDefault, true);
    });
    this.interval = setInterval(() => this.setBgColor(), 60000);

    this.notifyAll =
      localStorage.getItem("notifyAll") === null
        ? true
        : localStorage.getItem("notifyAll") === "true";
  },
  destroyed() {
    this.offJQuery();
    this.hidePopOver();
    eventBus.$off("openSearchCustomerModal");
    eventBus.$off("slotDurationSetting");
    eventBus.$off("notifyAllSetting");
    eventBus.$off("fetch-queue-data");

    clearInterval(this.interval);
  },
  updated() {
    this.setBgColor();
  },
};
</script>

<style scoped>
</style>

