<template>
  <b-modal
    modal-class="component-modal-treat-list small-text"
    class="small-text"
    id="treatListModal"
    ref="treatListModal"
    size="xl"
    title="รายการรักษา"
    ok-only
    ok-title="เลือก"
    scrollable
    no-close-on-backdrop
    @ok="saveSelectedItemes"
    @hidden="setDefault"
    ><b-form-group>
      <b-form-input
        autofocus
        v-model="filter"
        size=""
        type="search"
        id="filterInput"
        ref="filterInput"
        placeholder="ค้นหารายการรักษา เช่น ขูดหินปูน, อุดฟัน เป็นต้น"
        @input="searchFilter"
      ></b-form-input>
    </b-form-group>
    <b-table
      id="treatListTable"
      ref="treatListTable"
      hover
      selectable
      small
      sort-icon-left
      select-mode="single"
      :fields="fields"
      :items="txList"
      :filter="filter"
      @row-clicked="onRowSelected"
    >
      <template v-slot:cell(index)="row">{{ row.index + 1 }}.</template>
      <template v-slot:cell(favourite)="row">
        <b-button
          size="sm"
          variant="outline-info"
          :pressed="row.item.favorite"
          @click="favToggle(row.item)"
        >
          <i class="far fa-star"></i>
        </b-button>
      </template>
    </b-table>

    <Loading v-if="isLoading"></Loading>
  </b-modal>
</template>

<script>
import { mapGetters } from "vuex";
import Loading from "@/components/Loading";
// import "@/theme/modal/TreatList.scss";

export default {
  name: "TreatListModal",
  components: {
    Loading,
  },
  data() {
    return {
      isLoading: false,
      currentPage: 1,
      fields: [
        {
          key: "index",
          label: "",
        },
        {
          key: "code",
          label: "รหัส",
          sortable: true,
        },
        {
          key: "nameTh",
          label: "รายการรักษา",
          sortable: true,
        },
        {
          key: "nameEn",
          label: "ราการรักษา(Eng)",
          sortable: true,
        },
        {
          key: "price",
          label: "ราคา",
        },
        {
          key: "category",
          label: "ประเภทการรักษา",
          sortable: true,
        },
        {
          key: "favourite",
          label: "ใช้ประจำ",
          class: "text-center",
        },
      ],
      txItem: [],
      filter: null,
      fav: false,
    };
  },
  computed: {
    ...mapGetters({
      getTreatList: "moduleAppointment/getTreatList",
      getTreatCateList: "moduleAppointment/getTreatCateList",
      getTreatCateBy: "moduleAppointment/getTreatCateBy",
    }),
    txList() {
      return this.getTreatList.map(i => {
        return { ...i, category: this.getTreatCateBy(i.cateId, "id").nameTh };
      });
    },
  },
  methods: {
    show(data) {
      this.txItem = data;
      this.setSelectRow(data);
      this.$refs["treatListModal"].show();
      // this.$bvModal.show("treatListModal");
    },
    onRowSelected(item) {
      this.txItem = item;
    },
    setSelectRow(data) {
      if (data === null || data === undefined) return;
      this.$nextTick(() => {
        this.$nextTick(() => {
          let index = this.$refs.treatListTable.filteredItems.findIndex(
            filteredItem => filteredItem.id == data.id
          );
          if (index >= 0) {
            this.$refs.treatListTable.selectRow(index);
          }
        });
      });
    },
    saveSelectedItemes() {
      this.$emit("selectedTreatment", this.txItem);
    },
    favToggle(data) {
      this.isLoading = true;
      if (data.favorite == false) {
        this.$store
          .dispatch("moduleAppointment/updateTreatFevorite", {
            event: data,
            clinicUrl: this.$route.params.clinicUrl,
            branchUrl: this.$route.params.branchUrl,
          })
          .finally(() => {
            this.isLoading = false;
          });
      } else if (data.favorite == true) {
        this.$store
          .dispatch("moduleAppointment/deleteTreatFevorite", {
            event: data,
            clinicUrl: this.$route.params.clinicUrl,
            branchUrl: this.$route.params.branchUrl,
          })
          .finally(() => {
            this.isLoading = false;
          });
      }
    },
    setDefault() {
      this.filter = "";
      this.$emit("hidden");
    },
    searchFilter() {
      this.setSelectRow(this.txItem);
    },
  },
  mounted() {},
};
</script>

<style>
</style>