<template>
  <b-row>
    <div
      class="col-12 col-lg-2 divider-lg-right divider-bottom divider-lg-bottom-0 mb-3 mb-lg-0"
    >
      <PatientProfile
        :patient="patient"
        button-name="ข้อมูลติดต่อ"
        :button-click="
          () => {
            editCustomer(patient);
          }
        "
      />
    </div>
    <div class="col-12 col-lg-10">
      <AppointmentHistoryTable :patient="patient" />
    </div>
  </b-row>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import moment from "moment";
import { eventBus } from "@/main";

import AppointmentHistoryTable from "@/components/table/AppointmentHxTable.vue";

export default {
  name: "PatientAppointmentHistory",
  components: { AppointmentHistoryTable },
  props: ["patient"],
  methods: {
    editCustomer(patient) {
      this.$emit(
        "openCustomerModal",
        Object.assign({}, patient),
        this.appointment
      );
    },
  },
};
</script>

<style>
</style>