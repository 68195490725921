<template>
  <div class="smartcard">
    <b-button
      variant="primary"
      block
      @click="scanReaderData"
      style="line-height: 0.7"
      v-b-tooltip.hover
      title="สแกนบัตรประชาชนด้วย โปรแกรม DentCloud"
    >
      <span class="small-text">สแกน DC</span>
    </b-button>
    <Loading v-if="isLoading"></Loading>
  </div>
</template>

<script>
import Vue from "vue";
import VueNativeSock from "vue-native-websocket";
Vue.use(VueNativeSock, "ws://localhost:8088", {
  connectManually: true
});
const vm = new Vue();

import Loading from "@/components/Loading";

export default {
  name: "SmartCard",
  components: {
    Loading
  },
  data() {
    return {
      isLoading: false,
      socket: null,
      port: 8088,
      readerMessage: null,
      readerData: null
    };
  },
  mounted() {},
  destroyed() {
    vm.$disconnect();
  },
  created() {
    vm.$connect();
  },
  methods: {
    connect() {
      console.log("--- Smart Card Connecting ---");
      this.isLoading = true;
      this.$socket.send("READ");
      this.$options.sockets.onmessage = res => {
        let data = JSON.parse(res.data);
        if (!data.success) {
          this.$emit("showDialogToast", "warning", data.msgDetail);
          this.disconnect();
        } else {
          this.receiveReaderData(data);
        }
      };
    },
    disconnect() {
      this.isLoading = false;
      delete this.$options.sockets.onmessage;

      console.log("--- Smart Card Disconnected ---");
    },
    receiveReaderMessage(msg) {
      this.readerMessage = msg;
      console.log("Reader Message:", this.readerMessage);
      if (msg.toLowerCase().indexOf(this.port) != -1) {
        this.socket.emit("scancard");
      }
    },
    receiveReaderData(data) {
      if (data.success == true) {
        this.readerData = Object.assign(data.personal);
        this.$emit("getReaderData", this.readerData);
      } else {
        this.$emit("showDialogToast", "warning", data.msgDetail);
      }
      this.disconnect();
    },
    scanReaderData() {
      if (this.$socket.readyState === WebSocket.OPEN) {
        this.connect();
      } else {
        this.$emit(
          "showDialogToast",
          "warning",
          "WebSocket is in CONNECTING, CLOSING or CLOSED state."
        );
        // this.$emit(
        //   "showDialogToast",
        //   "warning",
        //   "อ่านบัตรประชาชนไม่สำเร็จ กรุณา\n1. ปิดโปรแกรมอ่านบัตรประชาชน(หน้าจอดำ) แล้วเปิดใหม่\n2. ปิดแล้วเปิด browser ใหม่\n3.เข้าโปรแกรม dClou.app ตามปกติ"
        // );
      }
    }
  }
};
</script>
