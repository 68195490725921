<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    viewBox="0 0 277.2 240.1"
  >
    <!-- <defs>
      <linearGradient
        id="_ÂÁ_Ï_ÌÌ_È_ËÂÌÚ_137"
        data-name="¡ÂÁ˚ÏˇÌÌ˚È „‡‰ËÂÌÚ 137"
        x1="337.79"
        y1="163.96"
        x2="335.99"
        y2="104.86"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0" stop-color="#cddcdc" />
        <stop offset="1" stop-color="#fcfcfc" />
      </linearGradient>
      <linearGradient
        id="_ÂÁ_Ï_ÌÌ_È_ËÂÌÚ_137-2"
        x1="340.25"
        y1="314.05"
        x2="338.6"
        y2="260"
        xlink:href="#_ÂÁ_Ï_ÌÌ_È_ËÂÌÚ_137"
      />
      <linearGradient
        id="_ÂÁ_Ï_ÌÌ_È_ËÂÌÚ_137-3"
        x1="258.49"
        y1="282.87"
        x2="254.25"
        y2="143.5"
        xlink:href="#_ÂÁ_Ï_ÌÌ_È_ËÂÌÚ_137"
      />
      <linearGradient
        id="_ÂÁ_Ï_ÌÌ_È_ËÂÌÚ_137-4"
        x1="423.18"
        y1="279.54"
        x2="419.12"
        y2="146.48"
        xlink:href="#_ÂÁ_Ï_ÌÌ_È_ËÂÌÚ_137"
      />
      <radialGradient
        id="_ÂÁ_Ï_ÌÌ_È_ËÂÌÚ_3"
        data-name="¡ÂÁ˚ÏˇÌÌ˚È „‡‰ËÂÌÚ 3"
        cx="248.3"
        cy="5686.38"
        r="16.7"
        gradientTransform="translate(-1106.7 -24415.3) scale(5.74 4.32)"
        xlink:href="#_ÂÁ_Ï_ÌÌ_È_ËÂÌÚ_137"
      />
    </defs>-->
    <g id="BG">
      <rect id="Q1BG" class="cls-1" width="277.2" height="240.07" rx="12.1" ry="12.1" />
    </g>
    <g id="Tooth_surfaces" data-name="Tooth surfaces">
      <g id="Buccal" class="surface" data-name="1" @click="select">
        <path
          class="cls-2"
          d="M237.1,144a55.3,55.3,0,0,1,12.6-18.4c8.3-7.9,17.5-11.5,27.5-14.3,18.1-5,33.5-5.9,56.4-6.3,32-.6,60.9.1,81.6,9.3,6.2,2.8,12.4,6.6,16.8,13.3a34.8,34.8,0,0,1,5.5,18.3l-41.2,16.3a52.7,52.7,0,0,0-12.1-7c-2.5-1.1-8.5-3.3-27.4-4.3-9.1-.5-15.8-.4-24.3-.2-11.9.2-17.9.3-22.3,1a71.4,71.4,0,0,0-27.5,11.1Z"
          transform="translate(-200.5 -91.3)"
        />
        <text class="cls-3" transform="translate(73.6 46.5)">Buccal/Labial</text>
      </g>
      <g id="Lingual" class="surface" data-name="2" @click="select">
        <path
          class="cls-2"
          d="M378.8,312.1a308.4,308.4,0,0,1-31.7,1.7c-21,.3-40.3-.4-61.7-3.3-15.7-2.1-23.2-4.2-30.7-9.7a49.1,49.1,0,0,1-14.4-17.3L284,261.7a29.5,29.5,0,0,0,6,4.6,31.5,31.5,0,0,0,12.6,3.9c19.3,2.2,35.7,2.3,35.7,2.3,17.1-.1,22.3-.2,29.6-1.6a57.1,57.1,0,0,0,15.9-5.4,54.6,54.6,0,0,0,8.9-5.8l45.3,19.5a64.4,64.4,0,0,1-21,22.1C405.7,308.4,394.6,310.3,378.8,312.1Z"
          transform="translate(-200.5 -91.3)"
        />
        <text class="cls-3" transform="translate(72.6 206.8)">Ligual/Palatal</text>
      </g>
      <g id="Mesial" class="surface" data-name="3" @click="select">
        <path
          class="cls-2"
          d="M277.8,243a119.3,119.3,0,0,0,6.2,18.7l-43.6,21.8a158.8,158.8,0,0,1-6-17.3c-1.5-5.5-4.8-18.1-5.9-51-1.4-41.6,2.6-55.6,5.7-63.9,1.1-3,2.2-5.5,2.9-7.1l45.8,18.6a57.5,57.5,0,0,0-6.7,13.3c-1.6,4.5-3.1,11.1-1.7,37.4C275.7,237.7,277.1,240,277.8,243Z"
          transform="translate(-200.5 -91.3)"
        />
        <text class="cls-3" transform="translate(58.9 153) rotate(-90)">Mesial</text>
      </g>
      <g id="Distal" class="surface" data-name="4" @click="select">
        <path
          class="cls-2"
          d="M437.4,145.9a45.9,45.9,0,0,1,7.5,11.2,44.7,44.7,0,0,1,4,13.5c2.2,16.8,1.2,37.6.9,44.5-1.2,27.9-.4,30.3-2.7,39.7a109.7,109.7,0,0,1-9,24.3l-45.3-19.5a103.8,103.8,0,0,0,7.3-21.8c1-4.8.9-6,1.7-40,.2-8.4.4-15.1-1.8-23.9a72.5,72.5,0,0,0-3.9-11.6Z"
          transform="translate(-200.5 -91.3)"
        />
        <text class="cls-7" transform="translate(221 93.6) rotate(90) scale(1.02 1)">Distal</text>
      </g>
      <g id="Occlusal" class="surface" data-name="5" @click="select">
        <path
          class="cls-2"
          d="M402.9,224.7a198.3,198.3,0,0,0,.6-31.5c-1-18-1.4-27.2-6.2-33s-12.7-8.1-26.6-10.8c-11.5-2.2-29.9-4.3-54.4.3-20.7,3.8-31,5.7-37.3,14.5s-5.8,16.1-6.6,33.7a167.2,167.2,0,0,0,.7,24.2c2.2,22.7,3.2,34,9.4,40.7s18.5,8.9,40.8,11.1a150.6,150.6,0,0,0,29.9.2c19.3-2,32.7-3.5,41.1-13.4C399.8,254.3,400.8,244.4,402.9,224.7Z"
          transform="translate(-200.5 -91.3)"
        />
        <text class="cls-9" transform="translate(91.5 115.8)">
          Occlusal
          <tspan x="13.2" y="30">Incisal</tspan>
        </text>
      </g>
    </g>
  </svg>
</template>

<script>
import $ from "jquery";

export default {
  props: {
    surfaceList: Array
  },
  data() {
    return {
      selectedSurfaces: []
    };
  },
  mounted() {
    this.selectSurfaces();
  },
  watch: {
    surfaceList() {
      this.selectSurfaces();
    }
  },
  methods: {
    selectSurfaces(){
      this.selectedSurfaces = Object.assign([], this.surfaceList);
      $(".surface").removeClass("selected");
      for (const surface of this.selectedSurfaces) {
        let el = $(`[data-name=${surface.id}]`).addClass("selected");
      }
    },
    select(e) {
      if (e.target.nodeName === "tspan") {
        e.target.parentNode.parentNode.classList.toggle("selected");
      } else {
        e.target.parentNode.classList.toggle("selected");
      }
      this.selectedSurfaces = [];
      document
        .querySelectorAll(".selected")
        .forEach(el => {
          if (Number(el.dataset.name) > 0) {
            this.selectedSurfaces.push(el.dataset.name)
          }
        });
      this.$emit("getToothSurfacesPopover", this.selectedSurfaces);
    }
  }
};
</script>

<style scoped>
.selected path {
  fill: #a6dcef;
  stroke: #774d54;
  stroke-width: 2;
}
.surface:hover * {
  stroke: #774d54;
  stroke-width: 2;
}

text {
  cursor: default;
}
.cls-1 {
  fill: #ffbbc6;
}

.cls-2,
.cls-4,
.cls-5,
.cls-6,
.cls-8 {
  stroke: #000;
  stroke-miterlimit: 10;
  stroke-width: 0.25px;
}

.cls-2 {
  /* fill: url(#_ÂÁ_Ï_ÌÌ_È_ËÂÌÚ_137); */
  fill: whitesmoke;
}

.cls-3 {
  font-size: 22px;
}

.cls-3,
.cls-7,
.cls-9 {
  fill: #774d54;
  /* font-family: Avenir-Roman, Avenir; */
}

/* .cls-4 {
  fill: url(#_ÂÁ_Ï_ÌÌ_È_ËÂÌÚ_137-2);
    fill: whitesmoke;

}

.cls-5 {
  fill: url(#_ÂÁ_Ï_ÌÌ_È_ËÂÌÚ_137-3);
    fill: whitesmoke;

}

.cls-6 {
  fill: url(#_ÂÁ_Ï_ÌÌ_È_ËÂÌÚ_137-4);
    fill: whitesmoke;

} */

.cls-7 {
  font-size: 21.6px;
}

/* .cls-8 {
  fill: url(#_ÂÁ_Ï_ÌÌ_È_ËÂÌÚ_3);
  fill: whitesmoke;
} */

.cls-9 {
  font-size: 25px;
}
</style>