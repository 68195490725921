<template>
  <b-modal
    id="ttd-consult-modal"
    ref="ttd-consult-modal"
    size="lg"
    centered
    title="ข้อมูลส่งปรึกษา Thaiteledentistry"
    ok-title="ส่งปรึกษา"
    ok-only
    @ok.prevent="createTTDCase"
  >
    <b-card body-class="p-2">
      <b-row>
        <b-col cols="3">
          <b-avatar
            class="mb-1"
            :variant="patient.contentId ? 'white' : 'info'"
            alt="User Image"
            size="60px"
            :src="viewFile(patient.contentId)"
            square
            rounded
          ></b-avatar>
        </b-col>
        <b-col cols="9">
          <p class="mb-0 narrow-spacing">DN: {{ patient.dn }}</p>
          <p
            v-if="patient.existDn"
            class="mb-1 text-bold text-muted description narrow-spacing"
          >
            DN(เดิม): {{ patient.existDn }}
          </p>
          <p class="mb-1 narrow-spacing">
            <strong>{{ fullName() }}</strong>
            <i v-if="patient.dob">( {{ calAge(patient.dob) }} ปี )</i>
          </p>
          <p v-if="patient.congenitalDisease" class="text-danger mb-1">
            โรคประจำตัว:{{ patient.congenitalDisease }}
          </p>
          <p v-if="patient.allergic" class="text-danger mb-1">
            แพ้ยา: {{ patient.allergic }}
          </p>
        </b-col>
      </b-row>
    </b-card>
    <b-card body-class="p-2">
      <b-form-group label="Case name:*">
        <b-form-input
          :class="{ 'is-invalid': $v.caseName.$error }"
          v-model="$v.caseName.$model"
          trim
        ></b-form-input>
      </b-form-group>
      <b-form-group label="Case decription:">
        <b-form-textarea v-model="caseDescription" lazy trim></b-form-textarea>
      </b-form-group>
    </b-card>

    <Gellery
      category="XRay"
      label="X-ray"
      photoSize="sm"
      :checkbox="true"
      :maxSelection="3"
      @imagesSelected="xray = $event"
    />
    <Gellery
      :category="['ExtraOralPhoto', 'IntraOralPhoto', 'OpdCard']"
      label="Photos"
      photoSize="sm"
      :checkbox="true"
      :maxSelection="3"
      @imagesSelected="caseRelate = $event"
    />
    <Dialog ref="Dialog"></Dialog>
    <Loading v-if="isLoading"></Loading>
  </b-modal>
</template>

<script>
import Gellery from "@/components/card/Gallery";

import { mapGetters, mapActions } from "vuex";
import Dialog from "@/components/modal/Dialog";
import Loading from "@/components/Loading";
import moment from "moment";

import { required, maxLength } from "vuelidate/lib/validators";

import { eventBus } from "../../main";

export default {
  name: "TTDConsultModal",
  components: {
    Dialog,
    Loading,
    Gellery,
  },
  props: ["patient"],
  data() {
    return {
      caseName: "",
      caseDescription: "",
      xray: [],
      caseRelate: [],
      file3d: [],
      isLoading: false,
    };
  },
  computed: {
    ...mapGetters({
      viewFile: "moduleContent/viewFile",
      userInfo: "moduleUser/getUserInfo",
    }),
  },
  validations: {
    caseName: { required },
    xray: { maxLength: maxLength(3) },
    caseRelate: { maxLength: maxLength(3) },
  },
  methods: {
    ...mapActions({
      createActiveCase: "moduleTTD/createActiveCase",
    }),
    fullName() {
      if (this.patient) {
        var tilte = this.patient.titleNameTh
          ? this.patient.titleNameTh
          : this.patient.titleNameEn
          ? this.patient.titleNameEn
          : "";
        var firstName = this.patient.firstNameTh
          ? this.patient.firstNameTh
          : this.patient.firstNameEn
          ? this.patient.firstNameEn
          : "";
        var lastName = this.patient.lastNameTh
          ? this.patient.lastNameTh
          : this.patient.lastNameEn
          ? this.patient.lastNameEn
          : "";
        var fullName = [tilte, firstName, lastName].join(" ");
        return fullName;
      } else {
        return "";
      }
    },
    calAge(dateString) {
      if (!dateString) return;
      const date = moment(dateString, "YYYY-MM-DD");
      return moment().diff(date, "years");
    },
    show() {
      this.$bvModal.show("ttd-consult-modal");
    },
    close() {
      this.$bvModal.hide("ttd-consult-modal");
    },
    handleImageSelected(data, event) {
      this.$set(data, event);
    },
    async createTTDCase() {
      this.$v.$touch();
      if (this.$v.$error) return;
      try {
        this.isLoading = true;
        const clinicUrl = this.$route.params.clinicUrl;
        const branchUrl = this.$route.params.branchUrl;
        const data = {
          title: this.caseName,
          desc: this.caseDescription,
          doctorUid: this.userInfo.uid,
          patientId: this.patient.id,
          xray: this.xray,
          caseRelate: this.caseRelate,
          file3d: this.file3d,
        };
        await this.createActiveCase({ clinicUrl, branchUrl, data });
        eventBus.$emit("alertToast", {
          message: "ส่งเคสให้ Thaiteledentistry เรียบร้อย",
          variant: "success",
        });
        this.close();
      } catch (err) {
        console.error(err);
        eventBus.$emit("alertToast", {
          message: "ไม่สามารถส่งเคสให้ Thaiteledentistry ได้",
          variant: "warning",
        });
      } finally {
        this.isLoading = false;
      }
    },
  },
};
</script>

<style>
</style>